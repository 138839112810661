import React from 'react';
import TextField from '@material-ui/core/TextField';
import RadioGroup from '@material-ui/core/RadioGroup';
import Radio from '@material-ui/core/Radio';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Mapper from '../../../../shared/util/mapper'
import AddressForm from './address_form'
import WareHouseField from './warehouse_field'
import PortField from './port_field'
import ShippingLineField from './shipping_line_field'
import ShipmentDocumentUpload from '../../../../shared/components/shipment_document_upload'
import { hasModule } from '../../../../shared/services/environment_service';

class TransportTab extends React.Component {
  
  constructor(props) {
    super(props)
    this.onValueUpdate = this.onValueUpdate.bind(this);

    if(!this.props.shipment.pickup_location) {
      this.props.shipment.pickup_location = { type: "none" };
    }
    if(!this.props.shipment.delivery_location) {
      this.props.shipment.delivery_location = { type: "none" };
    }
  }

  handleChange(e) {
    this.props.shipment[e.target.name] = e.target.value;
    if(!this.isSeaShipment()) {
      this.props.shipment.containers = [];
    }
    if(this.props.shipment.transport_type !== "ftl") {
      this.props.shipment.truck_quantity = undefined;
    }
    this.props.updateShipment(this.props.shipment);
  }

  handleAddressChange(property, e) {
    this.props.shipment[property] = { type: e.target.value, country: null };
    this.setState({ shipment: this.props.shipment });
  }

  onValueUpdate(property, value) {
    this.props.shipment[property] = value;
    this.props.updateShipment(this.props.shipment);
  }

  isSeaShipment() {
    return this.props.shipment.transport_type === "lcl" || this.props.shipment.transport_type === "fcl";
  }

  isFTL() {
    return this.props.shipment.transport_type === "ftl";
  }

  proofOfDeliveryUploaded(document) {
    this.props.shipment.proof_of_delivery = document;
    this.props.updateShipment(this.props.shipment);
  }

  proofOfDeliveryCleared() {
    this.props.shipment.proof_of_delivery.id = null;
    this.props.shipment.proof_of_delivery.file_name = null;
    this.props.updateShipment(this.props.shipment);
  }

  getLocations() {
    var locations = Object.keys(Mapper.locationTypes());
    if(!hasModule("warehouse")) {
      return locations.filter(function(e) { return e !== 'warehouse' })
    }
    return locations;
  }

  render() {
    return (
      <div>
    
        <div className="row row-small">
          <div className="col-md-6 col-lg-4">
            <FormControl
              variant="outlined"
              margin="dense"
              fullWidth>
              <InputLabel>Transport type *</InputLabel>
              <Select
                native
                required
                label="Transport type *"
                value={this.props.shipment.transport_type}
                name="transport_type"
                onChange={this.handleChange.bind(this)} >
                  <option key={"transport_type_empty"} value=""></option>
                  {Object.keys(Mapper.transportTypes()).map(transport_type => {
                    return (<option key={"transport_type_"+transport_type} value={transport_type}>{Mapper.fromTransportType(transport_type)}</option>);
                  })}
              </Select>
            </FormControl>
          </div>
          <div className="col-md-6 col-lg-4">
            <TextField
              label="Unloading reference"
              variant="outlined"
              fullWidth
              margin="dense"
              value={this.props.shipment.unloading_reference}
              name="unloading_reference"
              onChange={this.handleChange.bind(this)} 
            />
          </div>
        </div>

        <div className="row row-small">
          <div className="col-md-6 col-lg-4">
            <TextField
              label="Tracking link"
              variant="outlined"
              fullWidth
              margin="dense"
              value={this.props.shipment.tracking_link}
              name="tracking_link"
              onChange={this.handleChange.bind(this)} 
            />
          </div>
          <div className="col-md-6 col-lg-4">
            { this.props.shipment.id &&
              <ShipmentDocumentUpload 
                title="Proof of delivery"
                shipment_id={this.props.shipment.id}
                access="all"
                required={false}
                documentUploaded={this.proofOfDeliveryUploaded.bind(this)}
                documentCleared={this.proofOfDeliveryCleared.bind(this)}
                document={this.props.shipment.proof_of_delivery} />
            }
            { !this.props.shipment.id &&
              <TextField
                label="Proof of delivery"
                variant="outlined"
                fullWidth
                disabled={true}
                placeholder="Available after shipment is created"
                margin="dense"
                InputLabelProps={{
                  shrink: true,
                }}
              />
            }
          </div>
        </div>

        {this.isSeaShipment() &&
          <div className="row row-small">
            <div className="col-md-6 col-lg-4">
              <ShippingLineField
                property={"shipping_line"}
                shipping_line={this.props.shipment.shipping_line}
                shipping_lines={this.props.shipping_lines}
                onShippingLineUpdate={this.onValueUpdate}
              />
            </div>
          </div>
        }

        {this.isSeaShipment() &&
          <div className="row row-small">
            <div className="col-md-6 col-lg-4">
              <TextField
                label="Vessel name"
                variant="outlined"
                fullWidth
                margin="dense"
                value={this.props.shipment.vessel_name}
                name="vessel_name"
                onChange={this.handleChange.bind(this)} 
              />
            </div>
            <div className="col-md-6 col-lg-4">
              <TextField
                label="Vessel IMO"
                variant="outlined"
                fullWidth
                margin="dense"
                value={this.props.shipment.vessel_imo}
                name="vessel_imo"
                onChange={this.handleChange.bind(this)} 
              />
            </div>
          </div>
        }

        {this.isSeaShipment() &&
          <div className="row row-small">
            <div className="col-md-6 col-lg-4">
              <TextField
                label="Master BL number"
                variant="outlined"
                fullWidth
                margin="dense"
                value={this.props.shipment.master_bl_number}
                name="master_bl_number"
                onChange={this.handleChange.bind(this)} 
              />
            </div>
            <div className="col-md-6 col-lg-4">
              <TextField
                label="House BL number"
                variant="outlined"
                fullWidth
                margin="dense"
                value={this.props.shipment.house_bl_number}
                name="house_bl_number"
                onChange={this.handleChange.bind(this)} 
              />
            </div>
          </div>
        }

        {this.isSeaShipment() &&
          <div className="row row-small">
            <div className="col-md-6 col-lg-4">
              <TextField
                label="Booking number"
                variant="outlined"
                fullWidth
                margin="dense"
                value={this.props.shipment.booking_number}
                name="booking_number"
                onChange={this.handleChange.bind(this)} 
              />
            </div>
            <div className="col-md-6 col-lg-4">
              <TextField
                label="Initial ETA"
                variant="outlined"
                fullWidth
                margin="dense"
                type="date"
                InputLabelProps={{
                  shrink: true,
                }}
                value={this.props.shipment.initial_estimated_arrival_date}
                name="initial_estimated_arrival_date"
                onChange={this.handleChange.bind(this)} 
              />
            </div>
          </div>
        }

        <div className="row row-small">
          <div className="col-md-6 col-lg-4">
            <FormControl
              variant="outlined"
              margin="dense"
              fullWidth>
              <InputLabel>Truck fuel type</InputLabel>
              <Select
                native
                label="Truck fuel type"
                value={this.props.shipment.truck_fuel_type}
                name="truck_fuel_type"
                onChange={this.handleChange.bind(this)} >
                  <option key={"truck_fuel_type_empty"} value={undefined}></option>
                  {Object.keys(Mapper.truckFuelTypes()).map(truck_fuel_type => {
                    return (<option key={"truck_fuel_type_"+truck_fuel_type} value={truck_fuel_type}>{Mapper.fromTruckFuelType(truck_fuel_type)}</option>);
                  })}
              </Select>
            </FormControl>
          </div>
          {this.isFTL() &&
            <div className="col-md-6 col-lg-4">
              <TextField
                label="Number of trucks"
                variant="outlined"
                required
                fullWidth
                type="number"
                margin="dense"
                value={this.props.shipment.truck_quantity}
                name="truck_quantity"
                onChange={this.handleChange.bind(this)}
              />
            </div>
          }
        </div>
        

        <div className="list-row-item">
          <span className="list-row-item-header-text">Pickup location</span>

          <div className="row">
            <div className="col-md">
              <FormControl>
                <RadioGroup
                  row
                  name="pickup_location"
                  value={this.props.shipment.pickup_location.type}
                  onChange={this.handleAddressChange.bind(this, "pickup_location")}
                >
                  {this.getLocations().map(location_type => {
                    return (<FormControlLabel 
                      key={"pickup_location.location_type" + location_type}
                      value={location_type}
                      control={<Radio />}
                      label={Mapper.fromLocationType(location_type)}
                      />);
                  })}
                </RadioGroup>
              </FormControl>
            </div>
          </div>
          
          { this.props.shipment.pickup_location.type === "address" &&
            <AddressForm 
              property={"pickup_location"}
              required={true}
              location={this.props.shipment.pickup_location}
              account={this.props.account}
              countries={this.props.countries}
              onLocationUpdate={this.onValueUpdate}
            />
          }
          
          { this.props.shipment.pickup_location.type === "warehouse" &&
            <div className="row row-small">
              <div className="col-md-6 col-lg-4">
                <WareHouseField 
                  property={"pickup_location"}
                  required={true}
                  location={this.props.shipment.pickup_location}
                  warehouses={this.props.warehouses}
                  onLocationUpdate={this.onValueUpdate}
                />
              </div>
            </div>
          }
          
          { this.props.shipment.pickup_location.type === "port" &&
            <div className="row row-small">
              <div className="col-md-6 col-lg-4">
                <PortField
                  property={"pickup_location"}
                  required={true}
                  location={this.props.shipment.pickup_location}
                  ports={this.props.ports}
                  onLocationUpdate={this.onValueUpdate}
                />
              </div>
            </div>
          }

          <div className="row row-small">
            <div className="col-md-6 col-lg-4">
              <TextField
                label="Estimated pickup date"
                variant="outlined"
                fullWidth
                margin="dense"
                type="date"
                InputLabelProps={{
                  shrink: true,
                }}
                value={this.props.shipment.estimated_pickup_date}
                name="estimated_pickup_date"
                onChange={this.handleChange.bind(this)} 
              />
            </div>
            <div className="col-md-6 col-lg-4">
              <TextField
                label="Actual pickup date"
                variant="outlined"
                fullWidth
                margin="dense"
                type="date"
                InputLabelProps={{
                  shrink: true,
                }}
                value={this.props.shipment.actual_pickup_date}
                name="actual_pickup_date"
                onChange={this.handleChange.bind(this)} 
              />
            </div>
          </div>
        </div>

        { this.isSeaShipment() &&
          <div className="list-row-item">
            <span className="list-row-item-header-text">Port of loading</span>

            <div className="row row-small">
              <div className="col-md-4">
                <PortField 
                  property={"port_of_loading"}
                  location={this.props.shipment.port_of_loading}
                  ports={this.props.ports}
                  onLocationUpdate={this.onValueUpdate}
                />
              </div>
              <div className="col-md-4">
                <TextField
                  label="Estimated departure date"
                  variant="outlined"
                  fullWidth
                  margin="dense"
                  type="date"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  value={this.props.shipment.estimated_departure_date}
                  name="estimated_departure_date"
                  onChange={this.handleChange.bind(this)} 
                />
              </div>
              <div className="col-md-4">
                <TextField
                  label="Actual departure date"
                  variant="outlined"
                  fullWidth
                  margin="dense"
                  type="date"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  value={this.props.shipment.actual_departure_date}
                  name="actual_departure_date"
                  onChange={this.handleChange.bind(this)} 
                />
              </div>
            </div>
          </div>
        }

        { this.isSeaShipment() &&
          <div className="list-row-item">
            <span className="list-row-item-header-text">Port of discharge</span>

            <div className="row row-small">
              <div className="col-md-4">
                <PortField
                  property={"port_of_discharge"}
                  location={this.props.shipment.port_of_discharge}
                  ports={this.props.ports}
                  onLocationUpdate={this.onValueUpdate}
                />
              </div>
              <div className="col-md-4">
                <TextField
                  label="Estimated arrival date"
                  variant="outlined"
                  fullWidth
                  margin="dense"
                  type="date"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  value={this.props.shipment.estimated_arrival_date}
                  name="estimated_arrival_date"
                  onChange={this.handleChange.bind(this)} 
                />
              </div>
              <div className="col-md-4">
                <TextField
                  label="Actual arrival date"
                  variant="outlined"
                  fullWidth
                  margin="dense"
                  type="date"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  value={this.props.shipment.actual_arrival_date}
                  name="actual_arrival_date"
                  onChange={this.handleChange.bind(this)} 
                />
              </div>
            </div>
          </div>
        }

        <div className="list-row-item">
          <span className="list-row-item-header-text">Delivery location</span>

          <div className="row row-small">
            <div className="col-md">
              <FormControl>
                <RadioGroup
                  row
                  name="delivery_location"
                  value={this.props.shipment.delivery_location.type}
                  onChange={this.handleAddressChange.bind(this, "delivery_location")}
                >
                  {this.getLocations().map(location_type => {
                    return (<FormControlLabel
                      key={"delivery_location.location_type" + location_type}
                      value={location_type}
                      control={<Radio />}
                      label={Mapper.fromLocationType(location_type)}
                      />);
                  })}
                </RadioGroup>
              </FormControl>
            </div>
          </div>
          
          { this.props.shipment.delivery_location.type === "address" &&
            <AddressForm 
              property={"delivery_location"}
              required={true}
              location={this.props.shipment.delivery_location}
              account={this.props.account}
              countries={this.props.countries}
              onLocationUpdate={this.onValueUpdate}
            />
          }
          
          { this.props.shipment.delivery_location.type === "warehouse" &&
            <div className="row row-small">
              <div className="col-md-6 col-lg-4">
                <WareHouseField 
                  property={"delivery_location"}
                  required={true}
                  location={this.props.shipment.delivery_location}
                  warehouses={this.props.warehouses}
                  onLocationUpdate={this.onValueUpdate}
                />
              </div>
            </div>
          }
          
          { this.props.shipment.delivery_location.type === "port" &&
            <div className="row row-small">
              <div className="col-md-6 col-lg-4">
                <PortField
                  property={"delivery_location"}
                  required={true}
                  location={this.props.shipment.delivery_location}
                  ports={this.props.ports}
                  onLocationUpdate={this.onValueUpdate}
                />
              </div>
            </div>
          }

          <div className="row row-small">
            <div className="col-md-6 col-lg-4">
              <TextField
                label="Estimated delivery date"
                variant="outlined"
                fullWidth
                margin="dense"
                type="date"
                InputLabelProps={{
                  shrink: true,
                }}
                value={this.props.shipment.estimated_delivery_date}
                name="estimated_delivery_date"
                onChange={this.handleChange.bind(this)} 
              />
            </div>
            <div className="col-md-6 col-lg-4">
              <TextField
                label="Actual delivery date"
                variant="outlined"
                fullWidth
                margin="dense"
                type="date"
                InputLabelProps={{
                  shrink: true,
                }}
                value={this.props.shipment.actual_delivery_date}
                name="actual_delivery_date"
                onChange={this.handleChange.bind(this)} 
              />
            </div>
          </div>
        </div>

      </div>
      
    );
  }
}

export default TransportTab;

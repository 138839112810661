import React from 'react';
import PropTypes from 'prop-types';
import { confirmable } from 'react-confirm';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Icon from '@material-ui/core/Icon';
import Theme from './theme';

class PackageDialog extends React.Component {

  constructor(props) {
    super();
    this.state = {
      _package: props._package,
    };

    this.formRef = React.createRef();
  }

  handlePackageChange(property, e) {
    var value = e.target.value === "" ? null : e.target.value;
    this.state._package[property] = value;
    this.setState({ _package: this.state._package });
  }

  addPartToList() {
    if(!this.state._package.parts) {
      this.state._package.parts = [];
    }
    this.state._package.parts.push({ key: this.getUniqueKey() });
    this.setState({ _package: this.state._package });
  }

  removePartFromList(index) {
    this.state._package.parts.splice(index, 1);
    this.setState({ _package: this.state._package });
  }

  handlePartChange(index, property, e) {
    var value = e.target.value === "" ? null : e.target.value;
    this.state._package.parts[index][property] = value;
    this.setState({ _package: this.state._package });
  }

  submit(e) {
    e.preventDefault();

    if(!this.formRef.current.checkValidity()) {
      this.formRef.current.reportValidity();
      return;
    }

    this.props.proceed(this.state._package)
  }

  getUniqueKey() {
    return new Date().getTime() + Math.random();
  }

  render() {
    return (
      <Theme>
        <Dialog fullWidth={true} maxWidth={"lg"} open={this.props.show} onClose={this.props.dismiss} >
          <DialogTitle>{this.props.title}</DialogTitle>
          <DialogContent>
            <form autoComplete="off" ref={this.formRef} onSubmit={this.submit.bind(this)}>
              <div key={this.state._package.key} className="list-row-item">
                  <span className="list-row-item-header-text">Package #{this.state._package.index + 1}</span>

                  <div className="row">
                    <div className="col-md-4 col-lg-4">
                      <TextField
                        label="Package number"
                        variant="outlined"
                        fullWidth
                        required
                        margin="dense"
                        value={this.state._package.package_number}
                        onChange={(e) => {this.handlePackageChange("package_number", e)}}
                      />
                    </div>
                    <div className="col-md-4 col-lg-4">
                      <TextField
                        label="Loading number"
                        variant="outlined"
                        fullWidth
                        margin="dense"
                        value={this.state._package.loading_number}
                        onChange={(e) => {this.handlePackageChange("loading_number", e)}}
                      />
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-3 col-lg-3">
                      <div className='center-text'>
                        <span className='form-header-text'>Parts</span>
                      </div>
                    </div>
                  </div>

                  {this.state._package.parts && this.state._package.parts.map((part, index) => {
                  return (
                    <div key={this.state._package.key + part.key}>

                      <div className="row">
                        <div className="col-md-1 col-lg-1">
                          <div className='center-text center-text-horizontal'>
                            <span className='form-header-text'>Part #{index + 1}</span>
                          </div>
                        </div>
                        <div className="col-md-3 col-lg-3">
                          <TextField
                            label="Part number"
                            variant="outlined"
                            fullWidth
                            required
                            margin="dense"
                            value={part.part_number}
                            onChange={(e) => {this.handlePartChange(index, "part_number", e)}}
                          />
                        </div>
                        <div className="col-md-3 col-lg-3">
                          <TextField
                            label="Order number"
                            variant="outlined"
                            fullWidth
                            required
                            margin="dense"
                            value={part.order_number}
                            onChange={(e) => {this.handlePartChange(index, "order_number", e)}}
                          />
                        </div>
                        <div className="col-md-3 col-lg-3">
                          <TextField
                            label="Quantity"
                            variant="outlined"
                            fullWidth
                            type='number'
                            required
                            margin="dense"
                            value={part.quantity}
                            onChange={(e) => {this.handlePartChange(index, "quantity", e)}}
                          />
                        </div>
                        <div className="col-md-1 col-lg-1">
                          <Button
                            className="button-icon-only button-inline"
                            color="secondary"
                            type="button"
                            onClick={(e) => {this.removePartFromList(index, e)}}
                            startIcon={<Icon>clear</Icon>} />
                        </div>
                        <div className="col-md-1 col-lg-1" />
                        <div className="col-md-1 col-lg-1" />
                        <div className="col-md-3 col-lg-3">
                          <TextField
                            label="Reference"
                            variant="outlined"
                            fullWidth
                            margin="dense"
                            value={part.reference}
                            onChange={(e) => {this.handlePartChange(index, "reference", e)}}
                          />
                        </div>
                        <div className="col-md-3 col-lg-3">
                          <TextField
                            label="Country of origin"
                            variant="outlined"
                            fullWidth
                            margin="dense"
                            value={part.country_of_origin}
                            onChange={(e) => {this.handlePartChange(index, "country_of_origin", e)}}
                          />
                        </div>
                        <div className="col-md-3 col-lg-3">
                          <TextField
                            label="Description"
                            variant="outlined"
                            fullWidth
                            margin="dense"
                            value={part.description}
                            onChange={(e) => {this.handlePartChange(index, "description", e)}}
                          />
                        </div>
                        {this.props.for_shipment &&
                          <div className="col-md-2 col-lg-2" />
                        }
                        {this.props.for_shipment &&
                          <div className="col-md-1 col-lg-1"/>
                        }
                        {this.props.for_shipment &&
                          <div className="col-md-3 col-lg-3">
                            <TextField
                              label="Unloading quantity"
                              variant="outlined"
                              fullWidth
                              type='number'
                              margin="dense"
                              value={part.unloading_quantity}
                              onChange={(e) => {this.handlePartChange(index, "unloading_quantity", e)}}
                            />
                          </div>
                        }
                        {this.props.for_shipment &&
                          <div className="col-md-3 col-lg-3">
                            <TextField
                              label="Loading quantity"
                              variant="outlined"
                              fullWidth
                              type='number'
                              margin="dense"
                              value={part.loading_quantity}
                              onChange={(e) => {this.handlePartChange(index, "loading_quantity", e)}}
                            />
                          </div>
                        }
                        {this.props.for_shipment &&
                          <div className="col-md-5 col-lg-5" />
                        }
                        {this.props.for_shipment &&
                          <div className="col-md-1 col-lg-1"/>
                        }
                        {this.props.for_shipment &&
                          <div className="col-md-3 col-lg-3">
                            <TextField
                              label="Arrival date"
                              variant="outlined"
                              fullWidth
                              margin="dense"
                              type="date"
                              InputLabelProps={{
                                shrink: true,
                              }}
                              value={part.arrival_date}
                              onChange={(e) => {this.handlePartChange(index, "arrival_date", e)}}
                            />
                          </div>
                        }
                        {this.props.for_shipment &&
                          <div className="col-md-3 col-lg-3">
                            <TextField
                              label="Ready date"
                              variant="outlined"
                              fullWidth
                              margin="dense"
                              type="date"
                              InputLabelProps={{
                                shrink: true,
                              }}
                              value={part.ready_date}
                              onChange={(e) => {this.handlePartChange(index, "ready_date", e)}}
                            />
                          </div>
                        }
                        {this.props.for_shipment &&
                          <div className="col-md-3 col-lg-3">
                            <TextField
                              label="Dispatch date"
                              variant="outlined"
                              fullWidth
                              margin="dense"
                              type="date"
                              InputLabelProps={{
                                shrink: true,
                              }}
                              value={part.dispatch_date}
                              onChange={(e) => {this.handlePartChange(index, "dispatch_date", e)}}
                            />
                          </div>
                        }
                      </div>
                    </div>
                  )})}

                  <div className="row">
                    <div className="col-md">
                      <Button
                        variant="outlined"
                        color="primary"
                        onClick={(e) => {this.addPartToList(e)}}
                        startIcon={<Icon>add</Icon>}>
                        Add part
                      </Button>
                    </div>
                  </div>

                </div>
              </form>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => this.props.dismiss()} color="primary">Cancel</Button>
            <Button onClick={this.submit.bind(this)} color="primary">Save</Button>
          </DialogActions>
        </Dialog>
      </Theme>
    )
  }
}

PackageDialog.propTypes = {
  show: PropTypes.bool,            // from confirmable. indicates if the dialog is shown or not.
  proceed: PropTypes.func,         // from confirmable. call to close the dialog with promise resolved.
  cancel: PropTypes.func,          // from confirmable. call to close the dialog with promise rejected.
  dismiss: PropTypes.func,         // from confirmable. call to only close the dialog.
  title: PropTypes.string,
  _package: PropTypes.object,
  transport_type: PropTypes.string,
  for_shipment: PropTypes.bool,
}

export default confirmable(PackageDialog);

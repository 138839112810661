import React from 'react';
import Button from '@material-ui/core/Button';
import Icon from '@material-ui/core/Icon';
import ShipmentDocumentUpload from '../../../../shared/components/shipment_document_upload'

class DocumentsTab extends React.Component {

  addDocumentToList(property) {
    this.props.shipment[property].push({ key: this.getUniqueKey() });
    this.props.updateShipment(this.props.shipment);
  }

  documentUploaded(document, parent_data) {
    this.props.shipment[parent_data.property][parent_data.index] = document;
    this.props.updateShipment(this.props.shipment);
  }

  documentCleared(parent_data) {
    this.props.shipment[parent_data.property].splice(parent_data.index, 1);
    this.props.updateShipment(this.props.shipment);
  }

  getUniqueKey() {
    return new Date().getTime() + Math.random();
  }

  render() {
    return (
      <div>

        { !this.props.shipment.id &&
          <div className="row">
            <div className="col-md">
              <span><i>Document can be uploaded after the shipment is created</i></span>
            </div>
          </div>
        }

        { this.props.shipment.id &&
          <div>

            <div className="row">
              <div className="col-md">
                <div className="header-text">
                  <label>Public documents</label>
                </div>
              </div>
            </div>

            <div className="row row-small">
              <div className="col-md">

              {this.props.shipment.public_documents.map((document, index) => {
                return (
                  <div key={"document" + (document.id ? document.id : document.key)} className="row">
                    <div className="col-md-12 col-lg-6">
                      <ShipmentDocumentUpload 
                        title="Document"
                        shipment_id={this.props.shipment.id}
                        access="all"
                        alwayClearable={true}
                        documentUploaded={this.documentUploaded.bind(this)}
                        documentCleared={this.documentCleared.bind(this)}
                        document={document}
                        parent_data={{ property: "public_documents", index: index }} />
                    </div>
                  </div>
                );
              })}
              
              <div className="row">
                <div className="col-md">
                  <Button
                    variant="outlined"
                    color="primary"
                    onClick={this.addDocumentToList.bind(this, "public_documents")}
                    startIcon={<Icon>add</Icon>}>
                    Add document
                  </Button>
                </div>
              </div>
              
              </div>
            </div>

            <div className="tab-splitter" />

            <div className="row">
              <div className="col-md">
                <div className="header-text">
                  <label>Internal documents</label>
                </div>
              </div>
            </div>

            <div className="row row-small">
              <div className="col-md">

              {this.props.shipment.internal_documents.map((document, index) => {
                return (
                  <div key={"document" + (document.id ? document.id : document.key)} className="row">
                    <div className="col-md-12 col-lg-6">
                      <ShipmentDocumentUpload 
                        title="Document"
                        shipment_id={this.props.shipment.id}
                        access="nobody"
                        alwayClearable={true}
                        documentUploaded={this.documentUploaded.bind(this)}
                        documentCleared={this.documentCleared.bind(this)}
                        document={document}
                        parent_data={{ property: "internal_documents", index: index }} />
                    </div>
                  </div>
                );
              })}
              
              <div className="row">
                <div className="col-md">
                  <Button
                    variant="outlined"
                    color="primary"
                    onClick={this.addDocumentToList.bind(this, "internal_documents")}
                    startIcon={<Icon>add</Icon>}>
                    Add document
                  </Button>
                </div>
              </div>
              
              </div>
            </div>
          </div>
        }
        
      </div>
      
    );
  }
}

export default DocumentsTab;

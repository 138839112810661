import React from 'react';
import Button from '@material-ui/core/Button';
import Icon from '@material-ui/core/Icon';
import TextField from '@material-ui/core/TextField';
import RadioGroup from '@material-ui/core/RadioGroup';
import Radio from '@material-ui/core/Radio';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Alert from '@material-ui/lab/Alert';
import AsyncButton from '../../../../shared/components/async_button';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputAdornment from '@material-ui/core/InputAdornment';
import Mapper from '../../../../shared/util/mapper'
import Converter from '../../../../shared/util/converter'
import Calculator from '../../../../shared/util/calculator.js';
import DomUtil from '../../../../shared/util/dom_util.js';
import { calculateQuoteCosts } from '../../../../shared/services/quote_service.js';
import AddressForm from './address_form'
import WareHouseForm from './warehouse_form'
import PortForm from './port_form'
import confirm from '../../../../shared/components/confirm.js'
import confirmPallet from '../../../../shared/components/confirm_pallet.js'
import PalletsTable from '../../../../shared/components/pallets_table.js';
import confirmPackage from '../../../../shared/components/confirm_package.js'
import PackagesTable from '../../../../shared/components/packages_table.js';
import confirmContainer from '../../../../shared/components/confirm_container.js'
import ContainersTable from '../../../../shared/components/containers_table.js';
import confirmQuotePalletsImport from '../../../../shared/components/quote_confirm_pallets_import.js'
import confirmQuoteColliImport from '../../../../shared/components/quote_confirm_colli_import.js'
import { hasModule } from '../../../../shared/services/environment_service';

class QuoteCalculationForm extends React.Component {

  constructor(props) {
    super(props)
    this.calculateQuoteCosts = this.calculateQuoteCosts.bind(this);
    this.onLocationUpdate = this.onLocationUpdate.bind(this);

    if(!this.props.quote.pickup_location) {
      this.props.quote.pickup_location = { type: "none" };
    }
    if(!this.props.quote.delivery_location) {
      this.props.quote.delivery_location = { type: "none" };
    }

    this.state = {
      countries: this.props.countries,
      warehouses: this.props.warehouses,
      ports: this.props.ports,
      account: this.props.account,
      quote: this.props.quote,
      calculated_quote: {}
    };
  }
  
  calculateQuoteCosts(e) {
    e.preventDefault();
    var form = e.currentTarget;
    DomUtil.disableFormSubmitButtonClass(form);
    var quote_calc = Mapper.toQuoteCalculation(this.state.quote);
    calculateQuoteCosts(quote_calc).then((quote) => {
      this.setState({ calculated_quote: quote});
      DomUtil.enableFormSubmitButtonClass(form);
    }).catch(error => {
      var message = "Unable to calculate costs for this quote";
      if(error && error.response && error.response.data && error.response.data.message) {
        message = error.response.data.message;
      }
      var calculated_quote = { error_message: message };
      this.setState({ calculated_quote: calculated_quote});
      DomUtil.enableFormSubmitButtonClass(form);
    });
  }
  
  firstLetter(option) {
    return option.name[0].toUpperCase();
  }

  handleChange(e) {
    this.state.quote[e.target.name] = e.target.value;
    if(this.state.quote.transport_type !== "fcl") {
      this.state.quote.containers = [];
    }
    if(this.state.quote.transport_type !== "ftl") {
      this.state.quote.truck_quantity = undefined;
    }
    this.setState({ quote: this.state.quote });
  }

  handleSwitchChange(e) {
    this.state.quote[e.target.name] = e.target.checked;
    this.setState({ quote: this.state.quote });
  }

  handleAddressChange(property, e) {
    this.state.quote[property] = { type: e.target.value, country: null };
    this.setState({ quote: this.state.quote });
  }
  
  onLocationUpdate(property, location) {
    this.state.quote[property] = location;
    this.setState({ quote: this.state.quote });
  }

  getUniqueKey() {
    return new Date().getTime() + Math.random();
  }

  getTotalContainers() {
    var total = 0;
    this.state.quote.containers.forEach(container => {
      if(container.quantity) {
        total += Number(container.quantity);
      }
    });
    return total;
  }

  addContainerToList() {
    var container = { key: this.getUniqueKey(), index: this.state.quote.containers.length };
    confirmContainer("Add container", container, [ "quantity", "size", "type" ]).then((response_container) => {
      this.state.quote.containers.push(response_container);
      this.setState({ quote: this.state.quote });
    });
  }

  editContainerOfList(index) {
    const clone = JSON.parse(JSON.stringify(this.state.quote.containers[index]));
    clone.index = index;

    confirmContainer("Edit container", clone, [ "quantity", "size", "type" ]).then((response_container) => {
      this.state.quote.containers[index] = response_container;
      this.setState({ quote: this.state.quote });
    });
  }

  removeContainerFromList(index) {
    confirm("Please confirm", `Are you sure you want to delete Container ${index + 1}?`).then(() => {
      this.state.quote.containers.splice(index, 1);
      this.setState({ quote: this.state.quote });
    });
  }

  isLessThanTransport() {
    return this.state.quote.transport_type === "ltl" || this.state.quote.transport_type === "lcl";
  }

  addPalletToList() {
    if(!this.state.quote.pallets) {
      this.state.quote.pallets = [];
    }

    var _package = { key: this.getUniqueKey(), index: this.state.quote.pallets.length };
    confirmPallet("Add pallet", _package, this.isLessThanTransport()).then((response_pallet) => {
      this.state.quote.pallets.push(response_pallet);
      this.setState({ quote: this.state.quote });
    });
  }

  editPalletOfList(index) {
    const clone = JSON.parse(JSON.stringify(this.state.quote.pallets[index]));
    clone.index = index;

    confirmPallet("Edit pallet", clone, this.isLessThanTransport(), false).then((response_pallet) => {
      this.state.quote.pallets[index] = response_pallet;
      this.setState({ quote: this.state.quote });
    });
  }

  clonePalletOfList(index) {
    const clone = JSON.parse(JSON.stringify(this.state.quote.pallets[index]));
    clone.key = this.getUniqueKey()
    clone.index = this.state.quote.pallets.length;
    
    confirmPallet("Clone pallet", clone, this.isLessThanTransport(), false).then((response_pallet) => {
      this.state.quote.pallets.push(response_pallet);
      this.setState({ quote: this.state.quote });
    });
  }

  removePalletFromList(index) {
    confirm("Please confirm", `Are you sure you want to delete Pallet ${index + 1}?`).then(() => {
      this.state.quote.pallets.splice(index, 1);
      this.setState({ quote: this.state.quote });
    });
  }

  importPallets() {
    confirmQuotePalletsImport().then((data) => {
      if(!data.pallets) {
        return;
      }

      if(!this.state.quote.pallets) {
        this.state.quote.pallets = [];
      }

      if(data.replace) {
        this.state.quote.pallets = [];
      }

      for(const _package of data.pallets) {
        _package.key = this.getUniqueKey();
        this.state.quote.pallets.push(_package);
      }
      
      this.setState({ quote: this.state.quote });
    });
  }

  getTotalPallets() {
    if(!this.state.quote.pallets) {
      return 0;
    }
    return this.state.quote.pallets.length;
  }

  addPackageToList() {
    if(!this.state.quote.colli) {
      this.state.quote.colli = [];
    }
    var _package = { key: this.getUniqueKey(), index: this.state.quote.colli.length };
    confirmPackage("Add package", _package, this.state.quote.transport_type, false).then((response_package) => {
      this.state.quote.colli.push(response_package);
      this.setState({ quote: this.state.quote });
    });
  }

  editPackageOfList(index) {
    const clone = JSON.parse(JSON.stringify(this.state.quote.colli[index]));
    clone.index = index;

    confirmPackage("Edit package", clone, this.state.quote.transport_type, false).then((response_package) => {
      this.state.quote.colli[index] = response_package;
      this.setState({ quote: this.state.quote });
    });
  }

  clonePackageOfList(index) {
    const clone = JSON.parse(JSON.stringify(this.state.quote.colli[index]));
    clone.key = this.getUniqueKey()
    clone.index = this.state.quote.colli.length;
    
    confirmPackage("Clone package", clone, this.state.quote.transport_type, false).then((response_package) => {
      this.state.quote.colli.push(response_package);
      this.setState({ quote: this.state.quote });
    });
  }

  removePackageFromList(index) {
    confirm("Please confirm", `Are you sure you want to delete Package ${index + 1}?`).then(() => {
      this.state.quote.colli.splice(index, 1);
      this.setState({ quote: this.state.quote });
    });
  }

  importColli() {
    confirmQuoteColliImport().then((data) => {
      if(!data.colli) {
        return;
      }

      if(!this.state.quote.colli) {
        this.state.quote.colli = [];
      }

      if(data.replace) {
        this.state.quote.colli = [];
      }

      for(const _package of data.colli) {
        _package.key = this.getUniqueKey();
        this.state.quote.colli.push(_package);
      }
      
      this.setState({ quote: this.state.quote });
    });
  }

  getTotalPackages() {
    if(!this.state.quote.colli) {
      return 0;
    }
    return this.state.quote.colli.length;
  }

  getTotalParts() {
    if(!this.state.quote.colli) {
      return 0;
    }

    var total = 0;
    this.state.quote.colli.forEach(_package => {
      if(_package.parts) {
        total += _package.parts.length;
      }
    });
    return total;
  }

  getTotalWeight() {
    if(!this.state.quote.pallets) {
      return 0;
    }

    var total = 0;
    this.state.quote.pallets.forEach(pallet => {
        total += this.getTotalWeightOfPallet(pallet);
    });
    return total;
  }

  getTotalWeightOfPallet(pallet) {
    if(!pallet.weight_kg) {
      return 0;
    }
    return Number(pallet.weight_kg);
  }

  getTotalVolume() {
    if(!this.state.quote.pallets) {
      return 0;
    }

    var total = 0;
    this.state.quote.pallets.forEach(pallet => {
        total += this.getTotalVolumeOfPallet(pallet);
    });
    return total;
  }

  getTotalVolumeOfPallet(pallet) {
    var volume = 0;
    if(pallet.length_cm && pallet.width_cm && pallet.height_cm) {
      volume = (Number(pallet.length_cm) / 100) * (Number(pallet.width_cm) / 100) * (Number(pallet.height_cm) / 100);
    }
    return volume;
  }

  getTotalCostRows() {
    var rows = [];
    
    rows.push(
      <tr className="row-no-top-border-and-padding row-bold">
        <td className="header-text">Costs</td>
        <td className="header-text">Selling price</td>
        <td className="header-text">Cost price</td>
        <td className="header-text">Margin</td>
      </tr>
    );
    
    rows.push(this.getShippingCostRows());
    rows.push(this.getTaxesRows());
    rows.push(this.getInsuranceCostsRows());
  
    return rows;
  }

  getShippingCostRows() {
    var rows = []

    if(this.state.calculated_quote.shipping_costs) {
      var total_selling_price = 0;
      var total_cost_price = 0;
      var temp_rows = [];

      for(const shipping_cost of this.state.calculated_quote.shipping_costs) {
        var category = shipping_cost.name;
        var total_category_selling_price = 0;
        var total_category_cost_price = 0;
        var temp_category_rows = [];

        for(const shipping_cost_value of shipping_cost.values) {
          total_selling_price += Number(shipping_cost_value.value.selling_price);
          total_cost_price += Number(shipping_cost_value.value.cost_price);
          total_category_selling_price += Number(shipping_cost_value.value.selling_price);
          total_category_cost_price += Number(shipping_cost_value.value.cost_price);

          temp_category_rows.push(
            <tr>
              <td className="header-text">{this.doubleIndented(shipping_cost_value.name)}</td>
              <td>{this.doubleIndented(Converter.toCurrency("eur", shipping_cost_value.value.selling_price))}</td>
              <td>{this.doubleIndented(Converter.toCurrency("eur", shipping_cost_value.value.cost_price))}</td>
              <td>{this.doubleIndented(Converter.toCurrency("eur", shipping_cost_value.value.selling_price - shipping_cost_value.value.cost_price))}</td>
            </tr>
          );
        }
        
        temp_rows.push(
          <tr>
            <td className="header-text">{this.indented(category)}</td>
            <td>{this.indented(Converter.toCurrency("eur", total_category_selling_price))}</td>
            <td>{this.indented(Converter.toCurrency("eur", total_category_cost_price))}</td>
            <td>{this.indented(Converter.toCurrency("eur", total_category_selling_price - total_category_cost_price))}</td>
          </tr>
        );

        temp_rows.push(temp_category_rows);
      }

      rows.push(
        <tr className='row-bold'>
          <td className="header-text">Shipping costs</td>
          <td>{Converter.toCurrency("eur", total_selling_price)}</td>
          <td>{Converter.toCurrency("eur", total_cost_price)}</td>
          <td>{Converter.toCurrency("eur", total_selling_price - total_cost_price)}</td>
        </tr>
      );
  
      rows.push(temp_rows);
    }

    return rows;
  }

  getTaxesRows() {
    var rows = []

    if(this.state.calculated_quote.taxes && this.state.calculated_quote.taxes.length > 0) {
      var total_selling_price = 0;
      var total_cost_price = 0;
      var temp_rows = [];

      for(const tax of this.state.calculated_quote.taxes) {
        var category = tax.name;
        var total_category_selling_price = 0;
        var total_category_cost_price = 0;

        for(const tax_value of tax.values) {
          total_selling_price += Number(tax_value.value.selling_price);
          total_cost_price += Number(tax_value.value.cost_price);
          total_category_selling_price += Number(tax_value.value.selling_price);
          total_category_cost_price += Number(tax_value.value.cost_price);
        }
        
        temp_rows.push(
          <tr>
            <td className="header-text">{this.indented(category)}</td>
            <td>{this.indented(Converter.toCurrency("eur", total_category_selling_price))}</td>
            <td>{this.indented(Converter.toCurrency("eur", total_category_cost_price))}</td>
            <td>{this.indented(Converter.toCurrency("eur", total_category_selling_price - total_category_cost_price))}</td>
          </tr>
        );
      }

      rows.push(
        <tr className='row-bold'>
          <td className="header-text">Taxes</td>
          <td>{Converter.toCurrency("eur", total_selling_price)}</td>
          <td>{Converter.toCurrency("eur", total_cost_price)}</td>
          <td>{Converter.toCurrency("eur", total_selling_price - total_cost_price)}</td>
        </tr>
      );
  
      rows.push(temp_rows);
    }

    return rows;
  }

  getInsuranceCostsRows() {
    var rows = []

    if(this.state.calculated_quote.insurance_costs) {
      var selling_price = this.state.calculated_quote.insurance_costs.selling_price;
      var cost_price = this.state.calculated_quote.insurance_costs.cost_price;
      var margin = selling_price - cost_price;

      rows.push(
        <tr className='row-bold'>
          <td className="header-text">Insurance costs</td>
          <td>{Converter.toCurrency("eur", selling_price)}</td>
          <td>{Converter.toCurrency("eur", cost_price)}</td>
          <td>{Converter.toCurrency("eur", margin)}</td>
        </tr>
      );
    }
    
    return rows;
  }

  indented(element) {
    return <i>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{element}</i>;
  }

  doubleIndented(element) {
    return <i>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{element}</i>;
  }

  getLocations() {
    var locations = Object.keys(Mapper.locationTypes());
    if(!hasModule("warehouse")) {
      return locations.filter(function(e) { return e !== 'warehouse' })
    }
    return locations;
  }

  render() {
    return (
      <form autoComplete="off" onSubmit={this.calculateQuoteCosts}>
          
        <div className="container-body">

          <div className="row">
            <div className="col-md">
              <div className="form-header">
                <label>{this.props.title}</label>
              </div>
            </div>
          </div>

          <div className="row row-small">
            <div className="col-md-6 col-lg-4">
              <FormControl
                variant="outlined"
                margin="dense"
                fullWidth>
                <InputLabel>Incoterms *</InputLabel>
                <Select
                  native
                  required
                  label="Incoterms *"
                  value={this.state.quote.incoterms}
                  name="incoterms"
                  onChange={this.handleChange.bind(this)} >
                    <option key={"incoterms_empty"} value=""></option>
                    {Object.keys(Mapper.incoterms()).map(incoterms => {
                      return (<option key={"incoterms_"+incoterms} value={incoterms}>{Mapper.fromIncoterms(incoterms)}</option>);
                    })}
                </Select>
              </FormControl>
            </div>
            <div className="col-md-6 col-lg-4">
              <FormControl
                variant="outlined"
                margin="dense"
                fullWidth>
                <InputLabel>Consignee Type *</InputLabel>
                <Select
                  native
                  required
                  label="Consignee Type *"
                  value={this.state.quote.consignee_type}
                  name="consignee_type"
                  onChange={this.handleChange.bind(this)} >
                    <option key={"consignee_type_empty"} value=""></option>
                    {Object.keys(Mapper.consigneeTypes()).map(consignee_type => {
                      return (<option key={"consignee_type_" + consignee_type} value={consignee_type}>{Mapper.fromConsigneeType(consignee_type)}</option>);
                    })}
                </Select>
              </FormControl>
            </div>
          </div>

          <div className="list-row-item">
            <span className="list-row-item-header-text">Pickup location</span>

            <div className="row row-small">
              <div className="col-md">
                <FormControl>
                  <RadioGroup
                    row
                    name="pickup_location"
                    value={this.state.quote.pickup_location.type}
                    onChange={this.handleAddressChange.bind(this, "pickup_location")}
                  >
                  {this.getLocations().map(location_type => {
                      return (<FormControlLabel 
                        value={location_type}
                        control={<Radio />}
                        label={Mapper.fromLocationType(location_type)}
                        />);
                    })}
                  </RadioGroup>
                </FormControl>
              </div>
            </div>
            
            { this.state.quote.pickup_location.type === "address" &&
              <AddressForm 
                property={"pickup_location"}
                location={this.state.quote.pickup_location}
                account={this.state.account}
                countries={this.state.countries}
                onLocationUpdate={this.onLocationUpdate}
              />
            }
            
            { this.state.quote.pickup_location.type === "warehouse" &&
              <WareHouseForm 
                property={"pickup_location"}
                location={this.state.quote.pickup_location}
                warehouses={this.state.warehouses}
                onLocationUpdate={this.onLocationUpdate}
              />
            }
            
            { this.state.quote.pickup_location.type === "port" &&
              <PortForm 
                property={"pickup_location"}
                location={this.state.quote.pickup_location}
                ports={this.state.ports}
                onLocationUpdate={this.onLocationUpdate}
              />
            }
            
          </div>
          
          <div className="list-row-item">
            <span className="list-row-item-header-text">Delivery location</span>
  
            <div className="row row-small">
              <div className="col-md">
                <FormControl>
                  <RadioGroup
                    row
                    name="delivery_location"
                    value={this.state.quote.delivery_location.type}
                    onChange={this.handleAddressChange.bind(this, "delivery_location")}
                  >
                  {this.getLocations().map(location_type => {
                      return (<FormControlLabel 
                        value={location_type}
                        control={<Radio />}
                        label={Mapper.fromLocationType(location_type)}
                        />);
                    })}
                  </RadioGroup>
                </FormControl>
              </div>
            </div>
            
            { this.state.quote.delivery_location.type === "address" &&
              <AddressForm 
                property={"delivery_location"}
                location={this.state.quote.delivery_location}
                account={this.state.account}
                countries={this.state.countries}
                onLocationUpdate={this.onLocationUpdate}
              />
            }
            
            { this.state.quote.delivery_location.type === "warehouse" &&
              <WareHouseForm 
                property={"delivery_location"}
                location={this.state.quote.delivery_location}
                warehouses={this.state.warehouses}
                onLocationUpdate={this.onLocationUpdate}
              />
            }
            
            { this.state.quote.delivery_location.type === "port" &&
              <PortForm 
                property={"delivery_location"}
                location={this.state.quote.delivery_location}
                ports={this.state.ports}
                onLocationUpdate={this.onLocationUpdate}
              />
            }
            
          </div>
        
        </div>
        
        <div className="container-body">

          <div className="row">
            <div className="col-md">
              <div className="form-header">
                <label>Cargo Details</label>
              </div>
            </div>
          </div>

          <div className="row row-small">
            <div className="col-md">

              <div className="row">
                <div className="col-md-6 col-lg-4">
                  <TextField
                    label="Cargo Value"
                    variant="outlined"
                    required={this.state.quote.insurance === "full" || this.state.quote.insurance === "cargo_value"}
                    fullWidth
                    margin="dense"
                    value={this.state.quote.cargo_value}
                    name="cargo_value"
                    onChange={this.handleChange.bind(this)}
                    InputProps={{
                      inputComponent: Converter.numberFormatter,
                      startAdornment: <InputAdornment position="start">€</InputAdornment>,
                    }}
                  />
                </div>
                <div className="col-md-6 col-lg-4">
                  <FormControl
                    variant="outlined"
                    margin="dense"
                    fullWidth>
                    <InputLabel>Insurance *</InputLabel>
                    <Select
                      native
                      required
                      label="Insurance. *"
                      value={this.state.quote.insurance}
                      name="insurance"
                      onChange={this.handleChange.bind(this)} >
                        <option key="insurance_empty" value=""></option>
                        {Object.keys(Mapper.insuranceTypes()).map(insurance_type => {
                          return (<option key={"insurance_type_"+insurance_type} value={insurance_type}>{Mapper.fromInsuranceType(insurance_type)}</option>);
                        })}
                    </Select>
                  </FormControl>
                </div>
              </div>

              <div className="row row-small">
                <div className="col-md-6 col-lg-4">
                  <FormControl
                    variant="outlined"
                    margin="dense"
                    fullWidth>
                    <InputLabel>Transport type *</InputLabel>
                    <Select
                      native
                      required
                      label="Transport type *"
                      value={this.state.quote.transport_type}
                      name="transport_type"
                      onChange={this.handleChange.bind(this)} >
                        <option key={"transport_type_empty"} value=""></option>
                        {Object.keys(Mapper.transportTypes()).map(transport_type => {
                          return (<option key={"transport_type_"+transport_type} value={transport_type}>{Mapper.fromTransportType(transport_type)}</option>);
                        })}
                    </Select>
                  </FormControl>
                </div>
              </div>
              
            </div>
          </div>
        
        </div>

        
        {(this.state.quote.transport_type === "fcl") &&
          <div className="container-body">

            <div className="row">
              <div className="col-md">
                <div className="form-header">
                  <label>Container Details</label>
                </div>
              </div>
            </div>

            <div className="row row-small">
              <div className="col-md-6 col-lg-4">
                <table className="table table-details" >
                  <tbody>
                    <tr className="row-no-top-border-and-padding">
                      <td width={200} className="header-text">Total containers</td>
                      <td>{Converter.formatNumber(this.getTotalContainers(), 0)}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>

            <div className="row">
              <div className="col-md">
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={this.addContainerToList.bind(this)}
                  startIcon={<Icon>playlist_add_check</Icon>}>
                  Add container
                </Button>
              </div>
            </div>
            
            <div className="row">
              <div className="col-sm">
                <ContainersTable
                    name="quote_form_containers_table_state"
                    data={this.state.quote}
                    headers={[ "quantity", "size", "type" ]}
                    editable={true}
                    onEdit={this.editContainerOfList.bind(this)}
                    onDelete={this.removeContainerFromList.bind(this)}
                />
              </div>
            </div>          
          </div>
        }

        {(this.state.quote.transport_type === "ftl") &&
          <div className="container-body">

            <div className="row row-small">
              <div className="col-md">
                <div className="form-header">
                  <label>Truck Details</label>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-md-6 col-lg-4">
                <TextField
                  label="Number of trucks"
                  variant="outlined"
                  required
                  fullWidth
                  type="number"
                  margin="dense"
                  value={this.state.quote.truck_quantity}
                  name="truck_quantity"
                  onChange={this.handleChange.bind(this)}
                />
              </div>
            </div>

          </div>
        }
        
        <div className="container-body">

          <div className="row">
            <div className="col-md">
              <div className="form-header">
                <label>Pallets</label>
              </div>
            </div>
          </div>

          <div className="row row-small">
            <div className="col-md-6 col-lg-4">
              <table className="table table-details" >
                <tbody>
                  <tr className="row-no-top-border-and-padding">
                    <td width={200} className="header-text">Total pallets</td>
                    <td>{Converter.formatNumber(this.getTotalPallets(), 0)}</td>
                  </tr>
                  <tr>
                    <td width={200} className="header-text">Total volume (m3)</td>
                    <td>{Converter.formatNumber(this.getTotalVolume(), 2)} m3</td>
                  </tr>
                  <tr>
                    <td width={200} className="header-text">Total weight</td>
                    <td>{Converter.formatNumber(this.getTotalWeight(), 2)} kg</td>
                  </tr>
                  <tr>
                    <td width={200} className="header-text">Loading meter</td>
                    <td>{Converter.formatNumber(Calculator.calculateLoadingMeters(this.state.quote.pallets), 2)} ldm</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>

          <div className="row">
            <div className="col-md">
              <Button
                variant="outlined"
                color="primary"
                onClick={this.addPalletToList.bind(this)}
                startIcon={<Icon>playlist_add_check</Icon>}>
                Add pallet
              </Button>
              <Button
                className='align_right'
                variant="outlined"
                color="primary"
                onClick={this.importPallets.bind(this)}
                startIcon={<Icon>upload_file</Icon>}>
                Import
              </Button>
            </div>
          </div>
          
          <div className="row">
            <div className="col-sm">
              <PalletsTable
                  name="quote_form_pallets_table_state"
                  data={this.state.quote}
                  headers={[
                    "length_cm",
                    "width_cm",
                    "height_cm",
                    "volume_m3",
                    "weight_kg",
                    "hs_codes",
                    "stackable"
                  ]}
                  editable={true}
                  onEdit={this.editPalletOfList.bind(this)}
                  onClone={this.clonePalletOfList.bind(this)}
                  onDelete={this.removePalletFromList.bind(this)}
              />
            </div>
          </div>
        
        </div>
            
        <div className="container-body">

          <div className="row">
            <div className="col-md">
              <div className="form-header">
                <label>Colli Details</label>
              </div>
            </div>
          </div>

          <div className="row row-small">
            <div className="col-md-6 col-lg-4">
              <table className="table table-details" >
                <tbody>
                  <tr className="row-no-top-border-and-padding">
                    <td width={200} className="header-text">Total packages</td>
                    <td>{Converter.formatNumber(this.getTotalPackages(), 0)}</td>
                  </tr>
                  <tr>
                    <td width={200} className="header-text">Total parts</td>
                    <td>{Converter.formatNumber(this.getTotalParts(), 0)}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>

          <div className="row">
            <div className="col-md">
              <Button
                variant="outlined"
                color="primary"
                onClick={this.addPackageToList.bind(this)}
                startIcon={<Icon>playlist_add_check</Icon>}>
                Add package
              </Button>
              <Button
                className='align_right'
                variant="outlined"
                color="primary"
                onClick={this.importColli.bind(this)}
                startIcon={<Icon>upload_file</Icon>}>
                Import
              </Button>
            </div>
          </div>

          <div className="row">
            <div className="col-sm">
              <PackagesTable
                  name="quote_form_packages_table_state"
                  data={this.state.quote}
                  headers={[ 
                    "number",
                    "loading_number",
                    "order_number",
                    "quantity",
                    "reference",
                    "country_of_origin",
                    "description"
                  ]}
                  editable={true}
                  onEdit={this.editPackageOfList.bind(this)}
                  onClone={this.clonePackageOfList.bind(this)}
                  onDelete={this.removePackageFromList.bind(this)}
              />
            </div>
          </div>
        
        </div>
        
        <div className="container-body">

            {this.state.calculated_quote.error_message &&
              <div className="row">
                <div className="col-md-12">
                  <Alert severity="warning">{this.state.calculated_quote.error_message}</Alert>
                </div>
              </div>
            }

            {this.state.calculated_quote.shipping_costs &&
              <div className="row">
                <div className="col-md-12 col-lg-12">
                  <table className="table table-details" >
                    <tbody>
                      {this.getTotalCostRows()}
                    </tbody>
                  </table>
                </div>
              </div>
            }

            <div className="row">
              <div className="col-md">
                <AsyncButton
                  type="submit"
                  variant="outlined"
                  color="primary"
                  icon="calculate"
                  text="Calculate" />
              </div>
            </div>
        
        </div>
        
      </form>
    );
  }
}

export default QuoteCalculationForm;

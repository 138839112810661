import React from 'react';
import { logout } from '../../shared/services/authentication_service';
import { getShipmentCount } from '../../shared/services/shipment_service';
import EventBus from '../../shared/components/event_bus';
import ErrorHandler from '../../shared/util/error_handler';
import ParentMenu from '../../shared/components/parent_menu';

class Menu extends React.Component {

  constructor(props) {
    super(props)

    this.state = {
      shipment_count: {}
    }
  }

  componentDidMount() {
    this.getShipmentCount();
    
    EventBus.on("updateShipment", (data) => {
      this.getShipmentCount();
    });
  }

  getShipmentCount() {
    getShipmentCount().then((shipment_count) => {
      if (shipment_count) {
        this.setState({ shipment_count: shipment_count });
      }
    }).catch(error => {
      ErrorHandler.showError(error);
    });
  }

  getActiveShipmentsCount() {
    return this.state.shipment_count.opened + this.state.shipment_count.ready + this.state.shipment_count.received + this.state.shipment_count.in_transit + this.state.shipment_count.at_destination;
  }

  render() {
    return <ParentMenu
      children={this.props.children}
      location={this.props.location}
      topbar_items={[
        {
          name: "Settings",
          icon: "fas fa-cog",
          link: "settings"
        },
        {
          name: "Sign out",
          icon: "fas fa-sign-out-alt",
          onClick: () => logout()
        }
      ]}
      sidebar_items={[
        {
          title: "Services",
          items: [
            {
              name: "Dashboard",
              icon: "fas fa-chart-area",
              link: ""
            },
            {
              name: "Shipments",
              icon: "fas fa-truck",
              link: "shipments/active",
              sub_items: [
                {
                  name: "Active",
                  link: "shipments/active",
                  counter: this.getActiveShipmentsCount()
                },
                {
                  name: "All",
                  link: "shipments/all"
                }
              ]
            }
          ]
        },
        {
          title: "Company",
          items: [
            {
              name: "Account",
              icon: "fas fa-building",
              link: "account"
            }
          ]
        }
      ]}
    />;
  }
}

export default Menu;

import React from 'react';
import ReactDOM from 'react-dom';
import { Router, Route, Switch } from 'react-router-dom';
import { createBrowserHistory } from "history";
import TimeAgo from 'javascript-time-ago'
import en from 'javascript-time-ago/locale/en'
import './index.css';
import 'bootstrap-icons/font/bootstrap-icons.css';
/* Shared */
import CacheBuster from './apps/shared/components/cache_buster';
import Theme from './apps/shared/components/theme';
import ScrollToTop from "./apps/shared/components/scrolltop";
import Authenticated from './apps/shared/components/authenticated';
import NotAuthenticated from './apps/shared/components/not_authenticated';
import Login from './apps/shared/pages/login/index';
import MfaVerification from './apps/shared/pages/mfa/index';
import Redirect from './apps/shared/pages/redirect/index';
import Dashboard from './apps/shared/pages/dashboard/index';
import UpdateUser from './apps/shared/pages/update/index';
import ViewTerms from './apps/shared/pages/terms/index';
import SharedMenu from './apps/shared/components/menu';
import ActiveQuotes from './apps/shared/pages/quotes/active';
import AllQuotes from './apps/shared/pages/quotes/all';
import ShowQuote from './apps/shared/pages/quotes/show';
import ActiveShipments from './apps/shared/pages/shipments/active';
import AllShipments from './apps/shared/pages/shipments/all';
import ShowShipment from './apps/shared/pages/shipments/show';
import Warehouses from './apps/shared/pages/warehouse/index';
import WarehouseStock from './apps/shared/pages/warehouse/stock';
import Settings from './apps/shared/pages/settings/index';
import Account from './apps/shared/pages/account/index';
import InviteUser from './apps/shared/pages/account/invite';
import ShowUser from './apps/shared/pages/account/show';
import CreateAddress from './apps/shared/pages/address/create';
import ShowAddress from './apps/shared/pages/address/show';
import OpenInvoices from './apps/shared/pages/invoices/open';
import AllInvoices from './apps/shared/pages/invoices/all';
import ShowInvoice from './apps/shared/pages/invoices/show';
import Reports from './apps/shared/pages/reports/index';
import Support from './apps/shared/pages/support/index';
/* Customer */
import CustomerMenu from './apps/customer/components/menu';
import CreateQuote from './apps/customer/pages/quotes/create';
import EditQuote from './apps/customer/pages/quotes/edit';
/* Forwarder */
import ForwarderMenu from './apps/forwarder/components/menu';
import ForwarderAccounts from './apps/forwarder/pages/accounts/index';
import ForwarderAccount from './apps/forwarder/pages/accounts/show';
import CreateInvoiceGroup from './apps/forwarder/pages/invoice_groups/create';
import InvoiceGroup from './apps/forwarder/pages/invoice_groups/show';
import EditInvoiceGroup from './apps/forwarder/pages/invoice_groups/edit';
import CreateShipment from './apps/forwarder/pages/shipments/create';
import ForwarderEditShipment from './apps/forwarder/pages/shipments/edit';
import ForwarderInvoicehipment from './apps/forwarder/pages/shipments/invoicing';
import ForwarderCalculator from './apps/forwarder/pages/calculator/index';
import InvoicesRevenueReport from './apps/forwarder/pages/reports/invoices_revenue';
/* Agent */
import AgentMenu from './apps/agent/components/menu';
import AgentEditShipment from './apps/agent/pages/shipments/edit';
import AgentInboundShipment from './apps/agent/pages/shipments/inbound';
import AgentOutboundShipment from './apps/agent/pages/shipments/outbound';
/* Admin */
import AdminMenu from './apps/admin/components/menu';
import AdminAccounts from './apps/admin/pages/accounts/index';
import AdminAccount from './apps/admin/pages/accounts/show';

TimeAgo.addDefaultLocale(en)

const history = createBrowserHistory({forceRefresh:false});

ReactDOM.render((
  <CacheBuster>
    <Theme>
      <Router history={history} basename={process.env.PUBLIC_URL}>
        <ScrollToTop>
          <Authenticated history={history} types={["forwarder"]}>
            <Switch>
              <ForwarderMenu>
                <Switch>
                  <Route exact path={"/"} component={Dashboard} />
                  <Route exact path={"/quotes/active"} component={ActiveQuotes} />
                  <Route exact path={"/quotes/active/:quote_id"} component={ShowQuote} />
                  <Route exact path={"/quotes/all"} component={AllQuotes} />
                  <Route exact path={"/quotes/all/:quote_id"} component={ShowQuote} />
                  <Route exact path={"/shipments/active"} component={ActiveShipments} />
                  <Route exact path={"/shipments/active/create"} component={CreateShipment} />
                  <Route exact path={"/shipments/active/:shipment_id"} component={ShowShipment} />
                  <Route exact path={"/shipments/active/:shipment_id/edit"} component={ForwarderEditShipment} />
                  <Route exact path={"/shipments/active/:shipment_id/invoicing"} component={ForwarderInvoicehipment} />
                  <Route exact path={"/shipments/all"} component={AllShipments} />
                  <Route exact path={"/shipments/all/create"} component={CreateShipment} />
                  <Route exact path={"/shipments/all/:shipment_id"} component={ShowShipment} />
                  <Route exact path={"/shipments/all/:shipment_id/edit"} component={ForwarderEditShipment} />
                  <Route exact path={"/shipments/all/:shipment_id/invoicing"} component={ForwarderInvoicehipment} />
                  <Route exact path={"/warehouses"} component={Warehouses} />
                  <Route exact path={"/warehouses/:warehouse_code/stock"} component={WarehouseStock} />
                  <Route exact path={"/calculator"} component={ForwarderCalculator} />
                  <Route exact path={"/account"} component={Account} />
                  <Route exact path={"/account/invite"} component={InviteUser} />
                  <Route exact path={"/account/:user_id"} component={ShowUser} />
                  <Route exact path={"/account/addresses/create"} component={CreateAddress} />
                  <Route exact path={"/account/addresses/:address_id"} component={ShowAddress} />
                  <Route exact path={"/accounts"} component={ForwarderAccounts} />
                  <Route exact path={"/accounts/:account_id"} component={ForwarderAccount} />
                  <Route exact path={"/accounts/:account_id/invoice-groups/create"} component={CreateInvoiceGroup} />
                  <Route exact path={"/accounts/:account_id/invoice-groups/:invoice_group_id"} component={InvoiceGroup} />
                  <Route exact path={"/accounts/:account_id/invoice-groups/:invoice_group_id/edit"} component={EditInvoiceGroup} />
                  <Route exact path={"/reports"} component={Reports} />
                  <Route exact path={"/reports/invoices-revenue"} component={InvoicesRevenueReport} />
                  <Route exact path={"/invoices/open"} component={OpenInvoices} />
                  <Route exact path={"/invoices/open/:invoice_id"} component={ShowInvoice} />
                  <Route exact path={"/invoices/all"} component={AllInvoices} />
                  <Route exact path={"/invoices/all/:invoice_id"} component={ShowInvoice} />
                  <Route exact path={"/settings"} component={Settings} />
                  <Route exact path={`/document/terms-and-conditions`} component={ViewTerms} />
                </Switch>
              </ForwarderMenu>
            </Switch>
          </Authenticated>
          <Authenticated history={history} types={["customer"]}>
            <Switch>
              <CustomerMenu>
                <Switch>
                  <Route exact path={"/"} component={Dashboard} />
                  <Route exact path={"/quotes/active"} component={ActiveQuotes} />
                  <Route exact path={"/quotes/active/create"} component={CreateQuote} />
                  <Route exact path={"/quotes/active/:quote_id"} component={ShowQuote} />
                  <Route exact path={"/quotes/active/:quote_id/edit"} component={EditQuote} />
                  <Route exact path={"/quotes/all"} component={AllQuotes} />
                  <Route exact path={"/quotes/all/create"} component={CreateQuote} />
                  <Route exact path={"/quotes/all/:quote_id"} component={ShowQuote} />
                  <Route exact path={"/quotes/all/:quote_id/edit"} component={EditQuote} />
                  <Route exact path={"/shipments/active"} component={ActiveShipments} />
                  <Route exact path={"/shipments/active/:shipment_id"} component={ShowShipment} />
                  <Route exact path={"/shipments/all"} component={AllShipments} />
                  <Route exact path={"/shipments/all/:shipment_id"} component={ShowShipment} />
                  <Route exact path={"/warehouses"} component={Warehouses} />
                  <Route exact path={"/warehouses/:warehouse_code/stock"} component={WarehouseStock} />
                  <Route exact path={"/account"} component={Account} />
                  <Route exact path={"/account/invite"} component={InviteUser} />
                  <Route exact path={"/account/:user_id"} component={ShowUser} />
                  <Route exact path={"/account/addresses/create"} component={CreateAddress} />
                  <Route exact path={"/account/addresses/:address_id"} component={ShowAddress} />
                  <Route exact path={"/invoices/open"} component={OpenInvoices} />
                  <Route exact path={"/invoices/open/:invoice_id"} component={ShowInvoice} />
                  <Route exact path={"/invoices/all"} component={AllInvoices} />
                  <Route exact path={"/invoices/all/:invoice_id"} component={ShowInvoice} />
                  <Route exact path={"/settings"} component={Settings} />
                  <Route exact path={"/support"} component={Support} />
                  <Route exact path={`/document/terms-and-conditions`} component={ViewTerms} />
                </Switch>
              </CustomerMenu>
            </Switch>
          </Authenticated>
          <Authenticated history={history} types={["agent"]}>
            <Switch>
              <AgentMenu>
                <Switch>
                  <Route exact path={"/"} component={Dashboard} />
                  <Route exact path={"/shipments/active"} component={ActiveShipments} />
                  <Route exact path={"/shipments/active/:shipment_id"} component={ShowShipment} />
                  <Route exact path={"/shipments/active/:shipment_id/edit"} component={AgentEditShipment} />
                  <Route exact path={"/shipments/active/:shipment_id/inbound"} component={AgentInboundShipment} />
                  <Route exact path={"/shipments/active/:shipment_id/outbound"} component={AgentOutboundShipment} />
                  <Route exact path={"/shipments/all"} component={AllShipments} />
                  <Route exact path={"/shipments/all/:shipment_id"} component={ShowShipment} />
                  <Route exact path={"/shipments/all/:shipment_id/edit"} component={AgentEditShipment} />
                  <Route exact path={"/shipments/all/:shipment_id/inbound"} component={AgentInboundShipment} />
                  <Route exact path={"/shipments/all/:shipment_id/outbound"} component={AgentOutboundShipment} />
                  <Route exact path={"/account"} component={Account} />
                  <Route exact path={"/account/invite"} component={InviteUser} />
                  <Route exact path={"/account/:user_id"} component={ShowUser} />
                  <Route exact path={"/settings"} component={Settings} />
                  <Route exact path={"/support"} component={Support} />
                  <Route exact path={`/document/terms-and-conditions`} component={ViewTerms} />
                </Switch>
              </AgentMenu>
            </Switch>
          </Authenticated>
          <Authenticated history={history} types={["admin"]}>
            <Switch>
              <AdminMenu>
                <Switch>
                  <Route exact path={"/"} component={Dashboard} />
                  <Route exact path={"/quotes/active"} component={ActiveQuotes} />
                  <Route exact path={"/quotes/active/:quote_id"} component={ShowQuote} />
                  <Route exact path={"/quotes/all"} component={AllQuotes} />
                  <Route exact path={"/quotes/all/:quote_id"} component={ShowQuote} />
                  <Route exact path={"/shipments/active"} component={ActiveShipments} />
                  <Route exact path={"/shipments/active/:shipment_id"} component={ShowShipment} />
                  <Route exact path={"/shipments/all"} component={AllShipments} />
                  <Route exact path={"/shipments/all/create"} component={CreateShipment} />
                  <Route exact path={"/shipments/all/:shipment_id"} component={ShowShipment} />
                  <Route exact path={"/warehouses"} component={Warehouses} />
                  <Route exact path={"/warehouses/:warehouse_code/stock"} component={WarehouseStock} />
                  <Route exact path={"/account"} component={Account} />
                  <Route exact path={"/account/invite"} component={InviteUser} />
                  <Route exact path={"/account/:user_id"} component={ShowUser} />
                  <Route exact path={"/account/addresses/create"} component={CreateAddress} />
                  <Route exact path={"/account/addresses/:address_id"} component={ShowAddress} />
                  <Route exact path={"/accounts"} component={AdminAccounts} />
                  <Route exact path={"/accounts/:account_id"} component={AdminAccount} />
                  <Route exact path={"/invoices/open"} component={OpenInvoices} />
                  <Route exact path={"/invoices/open/:invoice_id"} component={ShowInvoice} />
                  <Route exact path={"/invoices/all"} component={AllInvoices} />
                  <Route exact path={"/invoices/all/:invoice_id"} component={ShowInvoice} />
                  <Route exact path={"/settings"} component={Settings} />
                  <Route exact path={`/document/terms-and-conditions`} component={ViewTerms} />
                </Switch>
              </AdminMenu>
            </Switch>
          </Authenticated>
          <NotAuthenticated history={history}>
            <Switch>
              <Route exact path={`/document/terms-and-conditions`} component={(props, state, params) => 
                  <SharedMenu {...props} >
                    <ViewTerms {...props} />
                  </SharedMenu>
                }
              />
            </Switch>
          </NotAuthenticated>
          <Switch>
              <Route exact path={`/update`} component={(props, state, params) => 
                  <SharedMenu {...props} >
                    <UpdateUser {...props} />
                  </SharedMenu>
                }
              />
              <Route exact path={`/login`} component={Login} />
              <Route exact path={`/mfa-verification`} component={MfaVerification} />
            <Route exact path={`/`} component={Redirect} />
          </Switch>
        </ScrollToTop>
      </Router>
    </Theme>
  </CacheBuster>
), document.getElementById('root'))

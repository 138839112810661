import React from 'react';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Icon from '@material-ui/core/Icon';
import PackagesTable from '../../../../../shared/components/packages_table.js';
import alertDialog from '../../../../../shared/components/alert.js';
import confirmOutbound from '../../../../../shared/components/confirm_outbound.js'

class ColliTab extends React.Component {

  constructor(props) {
    super(props)

    this.state = {
      shipment: props.shipment,
      stock: props.stock,
      package_number: ""
    };
  }

  onKeyDown(e) {
    if (e.key === 'Enter') {
      this.getPackage(e);
    }
  }

  handleChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  getPackage(e) {
    e.preventDefault();

    if(!this.state.package_number) {
      return;
    }

    var _package = this.findPackage(this.state.package_number)
    if(!_package) {
      alertDialog("Package not found", `Package number ${this.state.package_number} not found.`);
      return;
    }

    var stock_parts = [];
    if(this.state.stock) {
      for(const stock_part of this.state.stock) {
        if(stock_part.package_number === _package.package_number) {
          stock_parts.push(stock_part);
        }
      }
    }
    
    const clone = JSON.parse(JSON.stringify(_package));
    const stock_parts_clone = JSON.parse(JSON.stringify(stock_parts));
    confirmOutbound(`Package ${_package.package_number}`, clone, stock_parts_clone).then(() => {
      _package.ready_date = new Date();
      _package.loading_status = 'complete';
      for(const part of _package.parts) {
        part.ready_date = new Date();
        part.loading_status = 'complete';
        part.loading_quantity = part.quantity;
      }
    
      this.setState({ package_number: "" })
      this.props.updateShipment(this.state.shipment, true);
    });
  }

  findPackage(number) {
    return this.state.shipment.colli.find(c => this.match(c.package_number, number));
  }

  match(n1, n2) {
    var regex = /^(0+|\s)+|\s/g; // Remove spaces and leading zero's
    var match = n1.replace(regex, "") === n2.replace(regex, "");

    if(match) {
      return match;
    }
    
    var startsWithLetter = /^[A-Za-z].*$/.test(n2);
    if(startsWithLetter) {
      let result = n2.slice(1); // remove first letter
      return n1.replace(regex, "") === result.replace(regex, "");
    }

    var endsWithLetter = /^.*[A-Za-z]$/.test(n2);
    if(endsWithLetter) {
      let result = n2.slice(0, -1); // remove last letter
      return n1.replace(regex, "") === result.replace(regex, "");
    }

    return false;
  }

  getCount(status) {
    var count = 0;

    for(const _package of this.state.shipment.colli) {
      for(const part of _package.parts) {
        if(part.unloading_status === status) {
          count++;
        }
      }
    }

    return count;
  }

  render() {
    return (
      <div>        
        <div className="row row-small">
          <div className="col-md-12 col-lg-6">
            <table className="table table-details" >
              <tbody>
                <tr className="row-no-top-border-and-padding">
                  <td width={200} className="header-text">Todo</td>
                  <td>{this.getCount("todo")}</td>
                </tr>
                <tr>
                  <td width={200} className="header-text">Complete</td>
                  <td>{this.getCount("complete")}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        <div className="row row-small">
          <div className="col-md-3">
            <span className='text-padding'>Scan or enter package number..</span>
            <div className='file-input-download-parent'>
              <TextField
                label="Package number"
                variant="outlined"
                fullWidth
                required
                autoFocus
                margin="dense"
                name="package_number"
                value={this.state.package_number}
                onKeyDown={this.onKeyDown.bind(this)}
                onChange={this.handleChange.bind(this)} 
              />
              <Button
                className="button-icon-only button-inline clear-form-button"
                color="primary"
                type="button"
                disabled={!this.state.package_number}
                onClick={this.getPackage.bind(this)}
                startIcon={<Icon>search</Icon>} />
            </div>                      
          </div>
        </div>
        
        <div className="row">
          <div className="col-sm">
            <PackagesTable
                name="shipment_outbound_packages_table_state"
                data={this.state.shipment}
                headers={[
                  "number",
                  "loading_number",
                  "order_number",
                  "quantity",
                  "loading_quantity",
                  "scan_loading_status",
                  "reference",
                  "country_of_origin",
                  "description",
                  "ready_date"
                ]}
                groupDefaultExpanded={false}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default ColliTab;

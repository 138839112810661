import { DefaultApi } from "generated-shipments-api";
import { getHeaders, handleResponse } from './web_service';

export const API = new DefaultApi({
  basePath: window.appConfig.API_HOSTNAME
});

export function createShipment(data) {
  return API.createShipment(data, getHeaders()).then(handleResponse);
}

export function getShipment(id) {
  return API.getShipment(id, getHeaders()).then(handleResponse);
}

export function getShipmentStock(id) {
  return API.getShipmentStock(id, getHeaders()).then(handleResponse);
}

export function updateShipment(id, data) {
  return API.updateShipment(id, data, getHeaders()).then(handleResponse);
}

export function updateShipmentTransport(id, data) {
  return API.updateShipmentTransport(id, data, getHeaders()).then(handleResponse);
}

export function updateShipmentLoad(id, data) {
  return API.updateShipmentLoad(id, data, getHeaders()).then(handleResponse);
}

export function commentShipment(id, data) {
  return API.commentShipment(id, data, getHeaders()).then(handleResponse);
}

export function deleteShipment(id) {
  return API.deleteShipment(id, getHeaders()).then(handleResponse);
}

export function getShipments(page, size, sort, filter) {
  return API.getShipments(page, size, sort, filter, getHeaders()).then(handleResponse);
}

export function getShipmentCount() {
  return API.getShipmentCount(getHeaders()).then(handleResponse);
}

export function getShipmentStatistics() {
  return API.getShipmentStatistics(getHeaders()).then(handleResponse);
}

export function getShipmentDocument(id, documentId) {
  var headers = getHeaders();
  headers['responseType'] = 'blob';
  return API.getShipmentDocument(id, documentId, headers).then(handleResponse);
}

export function uploadShipmentDocument(id, file, access, progress_event) {
  var headers = getHeaders();
  headers['content-type'] = 'multipart/form-data';
  headers['onUploadProgress'] = progress_event;
  return API.uploadShipmentDocument(id, file, access, headers).then(handleResponse);
}

export function addShipmentDocuments(id, data) {
  return API.addShipmentDocuments(id, data, getHeaders()).then(handleResponse);
}

export function getContainersExampleDocument() {
  var headers = getHeaders();
  headers['responseType'] = 'blob';
  return API.getContainersExampleDocument(headers).then(handleResponse);
}

export function readContainersDocument(file, progress_event) {
  var headers = getHeaders();
  headers['content-type'] = 'multipart/form-data';
  headers['onUploadProgress'] = progress_event;
  return API.readContainersDocument(file, headers).then(handleResponse);
}

export function exportContainersToDocument(id) {
  var headers = getHeaders();
  headers['responseType'] = 'blob';
  return API.exportContainersToDocument(id, headers).then(handleResponse);
}

export function getPalletsExampleDocument() {
  var headers = getHeaders();
  headers['responseType'] = 'blob';
  return API.getPalletsExampleDocument(headers).then(handleResponse);
}

export function readPalletsDocument(file, progress_event) {
  var headers = getHeaders();
  headers['content-type'] = 'multipart/form-data';
  headers['onUploadProgress'] = progress_event;
  return API.readPalletsDocument(file, headers).then(handleResponse);
}

export function exportPalletsToDocument(id) {
  var headers = getHeaders();
  headers['responseType'] = 'blob';
  return API.exportPalletsToDocument(id, headers).then(handleResponse);
}

export function getColliExampleDocument() {
  var headers = getHeaders();
  headers['responseType'] = 'blob';
  return API.getColliExampleDocument(headers).then(handleResponse);
}

export function readColliDocument(file, progress_event) {
  var headers = getHeaders();
  headers['content-type'] = 'multipart/form-data';
  headers['onUploadProgress'] = progress_event;
  return API.readColliDocument(file, headers).then(handleResponse);
}

export function exportColliToDocument(id) {
  var headers = getHeaders();
  headers['responseType'] = 'blob';
  return API.exportColliToDocument(id, headers).then(handleResponse);
}

import React from 'react';
import AsyncButton from '../../../../shared/components/async_button';
import GeneralTab from './general_tab';
import CargoTab from './cargo_tab';
import TransportTab from './transport_tab';
import ContainersTab from './containers_tab';
import PalletsTab from './pallets_tab';
import ColliTab from './colli_tab';
import DocumentsTab from './documents_tab';

class ShipmentForm extends React.Component {

  constructor(props) {
    super(props)

    this.updateShipment = this.updateShipment.bind(this);
    this.validateAndUpdateShipment = this.validateAndUpdateShipment.bind(this);

    if(this.props.shipment.customer) {
      var customer = this.props.accounts.find(a => a.id === this.props.shipment.customer.id);
      if(customer) {
        this.props.shipment.customer = customer
      }
    }

    if(!this.props.shipment.proof_of_delivery) {
      this.props.shipment.proof_of_delivery = {}
    }

    this.state = {
      current_tab: "general",
      tabs: this.getTabs(this.props.shipment),
      countries: this.props.countries,
      warehouses: this.props.warehouses,
      ports: this.props.ports,
      shipping_lines: this.props.shipping_lines,
      account: this.props.account,
      accounts: this.props.accounts,
      shipment: this.props.shipment
    };

    this.formRefs = [
      { name: "general", ref:  React.createRef() },
      { name: "cargo", ref:  React.createRef() },
      { name: "transport", ref:  React.createRef() },
      { name: "containers", ref:  React.createRef() },
      { name: "pallets", ref:  React.createRef() },
      { name: "colli", ref:  React.createRef() },
      { name: "documents", ref:  React.createRef() }
    ];
  }
  
  getTabName(tab) {
    switch(tab) {
      case "general":
        return "General";
      case "cargo":
        return "Cargo Details";
      case "transport":
        return "Transport";
      case "containers":
        return "Containers";
      case "pallets":
        return "Pallets";
      case "colli":
        return "Colli";
      case "documents":
        return "Documents";
      default:
        return;
    }
  }
  
  getTabClassName(name) {
    var className = "nav-link";

    if(name === this.state.current_tab) {
      className += " active";
    }
    
    return className;
  }

  updateTab(tab) {
    this.setState({ current_tab: tab });
  }

  validateAndUpdateShipment(e) {
    e.preventDefault();

    for(const formRef of this.formRefs) {
      if(formRef.ref.current) {
        if(!formRef.ref.current.checkValidity()) {
          this.setState({ current_tab: formRef.name}, () => {
            formRef.ref.current.reportValidity();
          });
          return;
        }
      }
    }

    this.props.saveShipment(e);
  }

  updateShipment(shipment) {
    this.setState({ shipment: shipment, tabs: this.getTabs(shipment) });
  }

  getTabs(shipment) {
    if(this.isSeaShipment(shipment)) {
      return [ "general", "cargo", "transport", "containers", "pallets", "colli", "documents"];
    }
    return [ "general", "cargo", "transport", "pallets", "colli", "documents"];
  }

  getStyle(tab) {
    if(tab === this.state.current_tab) {
      return { display: "block" };
    } else {
      return { display: "none" };
    }
  }

  isSeaShipment(shipment) {
    if(!shipment) {
      return false;
    }
    return shipment.transport_type === "lcl" || shipment.transport_type === "fcl";
  }
  
  isFTL(shipment) {
    if(!shipment) {
      return false;
    }
    return shipment.transport_type === "ftl";
  }

  render() {
    return (
      <div>
                
        <div className="container-body">

          <div className="row">
            <div className="col-md">
              <div className="form-header">
                <label>{this.props.title}</label>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-md">
              <AsyncButton
                onClick={this.validateAndUpdateShipment}
                type="submit"
                variant="outlined"
                color="primary"
                icon="check"
                text="Save" />
            </div>
          </div>
          
        </div>
        
        <div className="container-body">
          <div className="row">
            <div className="col-md">
              <div className="form-header">
                <ul className="nav nav-tabs">
                  {this.state.tabs.map(tab => {
                    return (
                      <li key={tab} className="nav-item">
                        <a className={this.getTabClassName(tab)} onClick={this.updateTab.bind(this, tab)}>{this.getTabName(tab)}</a>
                      </li>
                    )
                  })}
                </ul>
              </div>
            </div>
          </div>
          <div className="tabs">
            <div style={ this.getStyle("general") } >
              <form autoComplete="off" ref={this.formRefs.find(ref => ref.name === "general").ref} >
                <GeneralTab shipment={this.state.shipment} accounts={this.state.accounts} updateShipment={this.updateShipment}/>
              </form>
            </div>
            <div style={ this.getStyle("cargo") } >
              <form autoComplete="off" ref={this.formRefs.find(ref => ref.name === "cargo").ref} >
                <CargoTab shipment={this.state.shipment} updateShipment={this.updateShipment} />
              </form>
            </div>
            <div style={ this.getStyle("transport") } >
              <form autoComplete="off" ref={this.formRefs.find(ref => ref.name === "transport").ref} >
                <TransportTab 
                  shipment={this.state.shipment}
                  account={this.state.account}
                  countries={this.state.countries}
                  shipping_lines={this.state.shipping_lines}
                  warehouses={this.state.warehouses}
                  ports={this.state.ports}
                  updateShipment={this.updateShipment} />
                </form>
            </div>
            { this.isSeaShipment(this.state.shipment) && 
              <div style={ this.getStyle("containers") } >
                <form autoComplete="off" ref={this.formRefs.find(ref => ref.name === "containers").ref} >
                  <ContainersTab shipment={this.state.shipment} updateShipment={this.updateShipment} />
                </form>
              </div>
            }
            <div style={ this.getStyle("pallets") } >
              <form autoComplete="off" ref={this.formRefs.find(ref => ref.name === "pallets").ref} >
                <PalletsTab shipment={this.state.shipment} updateShipment={this.updateShipment} />
              </form>
            </div>
            <div style={ this.getStyle("colli") } >
              <form autoComplete="off" ref={this.formRefs.find(ref => ref.name === "colli").ref} >
                <ColliTab shipment={this.state.shipment} updateShipment={this.updateShipment} />
              </form>
            </div>
            <div style={ this.getStyle("documents") } >
              <form autoComplete="off" ref={this.formRefs.find(ref => ref.name === "documents").ref} >
                <DocumentsTab shipment={this.state.shipment} updateShipment={this.updateShipment} />
              </form>
            </div>
          </div>
        </div>
        
      </div>
    );
  }
}

export default ShipmentForm;

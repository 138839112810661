import React from 'react';
import TextField from '@material-ui/core/TextField';
import AsyncButton from '../../../../shared/components/async_button';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Mapper from '../../../../shared/util/mapper'
import ShipmentDocumentUpload from '../../../../shared/components/shipment_document_upload'

class ShipmentForm extends React.Component {

  constructor(props) {
    super(props)

    if(this.props.shipment.customer) {
      var customer = this.props.accounts.find(a => a.id === this.props.shipment.customer.id);
      if(customer) {
        this.props.shipment.customer = customer
      }
    }

    if(!this.props.shipment.proof_of_delivery) {
      this.props.shipment.proof_of_delivery = {}
    }

    this.state = {
      countries: this.props.countries,
      ports: this.props.ports,
      account: this.props.account,
      accounts: this.props.accounts,
      shipment: this.props.shipment,
      selectable_customer_users: this.props.shipment.customer ? this.props.shipment.customer.users : []
    };
  }
  
  handleChange(e) {
    this.state.shipment[e.target.name] = e.target.value;
    if(this.state.shipment.transport_type !== "fcl") {
      this.state.shipment.containers = [];
    }
    this.setState({ shipment: this.state.shipment });
  }
  
  isSeaShipment() {
    return this.state.shipment.transport_type === "lcl" || this.state.shipment.transport_type === "fcl";
  }

  proofOfDeliveryUploaded(document) {
    this.state.shipment.proof_of_delivery = document;
    this.setState({ shipment: this.state.shipment });
  }

  proofOfDeliveryCleared() {
    this.state.shipment.proof_of_delivery.id = null;
    this.state.shipment.proof_of_delivery.file_name = null;
    this.setState({ shipment: this.state.shipment });
  }

  formatLocation(location) {
    if(!location) {
      return "-";
    }
    if(location.type === "address") {
      return location.address + ", " + location.postal_code + " " + location.city + ", " + location.country.name;
    }
    if(location.type === "warehouse") {
      return location.name + " (" + location.address + ", " + location.postal_code + " " + location.city + ", " + location.country.name + ")";
    }
    if(location.type === "port") {
      return location.name + ", " + location.country.name;
    }
    return "-";
  }

  render() {
    return (
      <form autoComplete="off" onSubmit={this.props.saveShipment}>
        
        <div className="container-body">

          <div className="row">
            <div className="col-md">
              <div className="form-header">
                <label>{this.props.title}</label>
              </div>
            </div>
          </div>

          <div className="row row-small">
            <div className="col-md">
              
              <div className="row">
                <div className="col-md-6 col-lg-4">
                  <FormControl
                    variant="outlined"
                    margin="dense"
                    fullWidth>
                    <InputLabel>Status *</InputLabel>
                    <Select
                      native
                      required
                      label="Status *"
                      value={this.state.shipment.status}
                      name="status"
                      onChange={this.handleChange.bind(this)} >
                        <option key={"shipment_status_empty"} value=""></option>
                        {Object.keys(Mapper.agentShipmentStatuses()).map(status => {
                          return (<option key={"shipment_status_" + status} value={status}>{Mapper.fromShipmentStatus(status)}</option>);
                        })}
                    </Select>
                  </FormControl>
                </div>
              </div>
            </div>
          </div>
        
        </div>
        
        <div className="container-body">

          <div className="row">
            <div className="col-md">
              <div className="form-header">
                <label>Transport</label>
              </div>
            </div>
          </div>

          <div className="row row-small">
            <div className="col-md">

              <div className="row">
                <div className="col-md-6 col-lg-4">
                  <TextField
                    label="Unloading reference"
                    variant="outlined"
                    fullWidth
                    margin="dense"
                    value={this.state.shipment.unloading_reference}
                    name="unloading_reference"
                    onChange={this.handleChange.bind(this)} 
                  />
                </div>
              </div>

              <div className="row">
                <div className="col-md-6 col-lg-4">
                  <TextField
                    label="Tracking link"
                    variant="outlined"
                    fullWidth
                    margin="dense"
                    value={this.state.shipment.tracking_link}
                    name="tracking_link"
                    onChange={this.handleChange.bind(this)} 
                  />
                </div>
                <div className="col-md-6 col-lg-4">
                  <ShipmentDocumentUpload 
                    title="Proof of delivery"
                    shipment_id={this.state.shipment.id}
                    access="all"
                    required={false}
                    documentUploaded={this.proofOfDeliveryUploaded.bind(this)}
                    documentCleared={this.proofOfDeliveryCleared.bind(this)}
                    document={this.state.shipment.proof_of_delivery} />
                </div>
              </div>
              
              <div className="list-row-item">
                <span className="list-row-item-header-text">Pickup location</span>

                <div className="row">
                  <div className="col-md-4">
                    <div className="center-text">
                      <span>{this.formatLocation(this.state.shipment.pickup_location)}</span>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <TextField
                      label="Estimated pickup date"
                      variant="outlined"
                      fullWidth
                      margin="dense"
                      type="date"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      value={this.state.shipment.estimated_pickup_date}
                      name="estimated_pickup_date"
                      onChange={this.handleChange.bind(this)} 
                    />
                  </div>
                  <div className="col-md-4">
                    <TextField
                      label="Actual pickup date"
                      variant="outlined"
                      fullWidth
                      margin="dense"
                      type="date"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      value={this.state.shipment.actual_pickup_date}
                      name="actual_pickup_date"
                      onChange={this.handleChange.bind(this)} 
                    />
                  </div>
                </div>
              </div>

              { this.isSeaShipment() &&
                <div className="list-row-item">
                  <span className="list-row-item-header-text">Port of loading</span>

                  <div className="row">
                    <div className="col-md-4">
                      <div className="center-text">
                        <span>{this.formatLocation(this.state.shipment.port_of_loading)}</span>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <TextField
                        label="Estimated departure date"
                        variant="outlined"
                        fullWidth
                        margin="dense"
                        type="date"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        value={this.state.shipment.estimated_departure_date}
                        name="estimated_departure_date"
                        onChange={this.handleChange.bind(this)} 
                      />
                    </div>
                    <div className="col-md-4">
                      <TextField
                        label="Actual departure date"
                        variant="outlined"
                        fullWidth
                        margin="dense"
                        type="date"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        value={this.state.shipment.actual_departure_date}
                        name="actual_departure_date"
                        onChange={this.handleChange.bind(this)} 
                      />
                    </div>
                  </div>
                </div>
              }

              { this.isSeaShipment() &&
                <div className="list-row-item">
                  <span className="list-row-item-header-text">Port of discharge</span>

                  <div className="row">
                    <div className="col-md-4">
                      <div className="center-text">
                        <span>{this.formatLocation(this.state.shipment.port_of_discharge)}</span>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <TextField
                        label="Estimated arrival date"
                        variant="outlined"
                        fullWidth
                        margin="dense"
                        type="date"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        value={this.state.shipment.estimated_arrival_date}
                        name="estimated_arrival_date"
                        onChange={this.handleChange.bind(this)} 
                      />
                    </div>
                    <div className="col-md-4">
                      <TextField
                        label="Actual arrival date"
                        variant="outlined"
                        fullWidth
                        margin="dense"
                        type="date"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        value={this.state.shipment.actual_arrival_date}
                        name="actual_arrival_date"
                        onChange={this.handleChange.bind(this)} 
                      />
                    </div>
                  </div>
                </div>
              }

              <div className="list-row-item">
                <span className="list-row-item-header-text">Delivery location</span>
    
                <div className="row">
                  <div className="col-md-4">
                    <div className="center-text">
                      <span>{this.formatLocation(this.state.shipment.delivery_location)}</span>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <TextField
                      label="Estimated delivery date"
                      variant="outlined"
                      fullWidth
                      margin="dense"
                      type="date"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      value={this.state.shipment.estimated_delivery_date}
                      name="estimated_delivery_date"
                      onChange={this.handleChange.bind(this)} 
                    />
                  </div>
                  <div className="col-md-4">
                    <TextField
                      label="Actual delivery date"
                      variant="outlined"
                      fullWidth
                      margin="dense"
                      type="date"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      value={this.state.shipment.actual_delivery_date}
                      name="actual_delivery_date"
                      onChange={this.handleChange.bind(this)} 
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        
        </div>

        <div className="container-body">

          <div className="row">
            <div className="col-md">

              <div className="row row-small">
                <div className="col-md">
                  <AsyncButton
                    type="submit"
                    variant="outlined"
                    color="primary"
                    icon="check"
                    text="Save" />
                </div>
              </div>

            </div>
          </div>
        
        </div>
        
      </form>
    );
  }
}

export default ShipmentForm;

import React from 'react';

class FlowView extends React.Component {

  constructor(props) {
    super(props)

    this.state = {
      flow: this.createFlow(this.props.status)
    };
  }

  componentWillReceiveProps(nextProps) {
    this.setState({ flow: this.createFlow(nextProps.status) });
  }

  getFlow() {
    var flow = [];
    for (let i = 0; i < this.props.flow.length; i++) {
      var item = this.props.flow[i];
      flow.push({ key: item.key, name: item.name, classes: []})
    }
    return flow;
  }

  createFlow(status) {
    var flow = this.getFlow();
    var containsState = flow.some(entry => entry.key === status);
    var stateFound = false;
    for (const [index, entry] of flow.entries()) {
      if(index !== 0) {
        entry.classes.push("left");
      } else {
        entry.classes.push("first");
      }
      if(index !== flow.length - 1) {
        entry.classes.push("right");
      } else {
        entry.classes.push("last");
      }
      if(!containsState) {
        entry.classes.push("skipped");
      } else if(status === entry.key) {
        entry.classes.push("active");
        stateFound = true;
      } else if(!stateFound) {
        entry.classes.push("passed");
      }
    }
    
    return flow;
  }
  
  render() {
    return (
      <div className={"flowBoxes flowBoxes-" + this.props.flow.length }>
        {this.state.flow.map(entry => {
          return (<div key={entry.key} className={entry.classes.join(' ')}>            
            <span><i style={{display:"none"}} className="fas fa-check passed"/>&nbsp;{entry.name}</span>
          </div>);
        })}
      </div>
    );
  }
}

export default FlowView;

import React from 'react';
import { Link } from 'react-router-dom';
import Mapper from '../../../util/mapper.js';
import LoadingContent from '../../../components/loading_content.js';
import Converter from '../../../util/converter.js'
import MultilineTextField from '../../../components/multiline_textfield.js';
import AsyncButton from '../../../components/async_button.js';
import { commentShipment } from '../../../services/shipment_service.js';
import ErrorHandler from '../../../util/error_handler.js';
import EventBus from '../../../components/event_bus.js';
import DomUtil from '../../../util/dom_util.js';
import confirm from '../../../components/confirm.js'

class GeneralTab extends React.Component {

  constructor(props) {
    super(props)

    this.state = {
      provide_shipment_patch: {
        validity_days: 30
      },
      review_shipment_patch: {},
      is_agent_commenting: false,
      agent_comment_shipment: {},
      is_customer_commenting: false,
      customer_comment_shipment: {}
    };
  }

  getComments(comments) {
    if(!comments || comments.length === 0) {
      return (
        <div className='spacing-bottom' key="no_comments">
          <span><i>There are no comments</i></span>
        </div>
      );
    };

    var sorted_comments = comments.sort((a, b) => a.created_at.localeCompare(b.created_at));

    return (
      <div className='spacing-bottom'>
        { sorted_comments.map((comment, index) => {
            return (
              <div key={"comment" + index}>
                <span><b>{comment.from.name}:</b>&nbsp;&nbsp;<i>({Converter.toDatetime(comment.created_at)})</i></span>
                <br/>
                <span style={{ whiteSpace: "pre", textWrap: "wrap" }}>{comment.message}</span>
              </div>
            );
          }) }
      </div>
    );
  }

  addAgentComment(e) {
    e.preventDefault();
    this.setState({ is_agent_commenting: true });
  }

  cancelAddAgentComment(e) {
    e.preventDefault();
    this.setState({ is_agent_commenting: false, agent_comment_shipment: {} });
  }

  handleAgentCommentShipmentChange(e) {
    var value = e.target.value === "" ? null : e.target.value;
    this.state.agent_comment_shipment[e.target.name] = value;
    this.setState({ agent_comment_shipment: this.state.agent_comment_shipment })
  }

  agentCommentShipment(e) {
    e.preventDefault();
    var button = e.currentTarget;
    confirm("Please confirm", "Are you sure to add this comment?").then(() => {
      DomUtil.disableClass(button);

      var comment = { type: "agent", message: this.state.agent_comment_shipment.comment };
      commentShipment(this.props.shipment.id, comment).then((shipment) => {
        EventBus.dispatch("updateShipment");
        this.setState({ is_agent_commenting: false, agent_comment_shipment: {} });
        this.props.shipmentUpdated(shipment);
        DomUtil.enableClass(button);
      }).catch((error) => {
        ErrorHandler.showError(error);
        DomUtil.enableClass(button);
      });
    });
  }

  addCustomerComment(e) {
    e.preventDefault();
    this.setState({ is_customer_commenting: true });
  }

  cancelAddCustomerComment(e) {
    e.preventDefault();
    this.setState({ is_customer_commenting: false, customer_comment_shipment: {} });
  }

  handleCustomerCommentShipmentChange(e) {
    var value = e.target.value === "" ? null : e.target.value;
    this.state.customer_comment_shipment[e.target.name] = value;
    this.setState({ customer_comment_shipment: this.state.customer_comment_shipment })
  }

  customerCommentShipment(e) {
    e.preventDefault();
    var button = e.currentTarget;
    confirm("Please confirm", "Are you sure to add this comment?").then(() => {
      DomUtil.disableClass(button);

      var comment = { type: "customer", message: this.state.customer_comment_shipment.comment };
      commentShipment(this.props.shipment.id, comment).then((shipment) => {
        EventBus.dispatch("updateShipment");
        this.setState({ is_customer_commenting: false, customer_comment_shipment: {} });
        this.props.shipmentUpdated(shipment);
        DomUtil.enableClass(button);
      }).catch((error) => {
        ErrorHandler.showError(error);
        DomUtil.enableClass(button);
      });
    });
  }
  
  isShipmentOfType(shipment, types) {
    if(!shipment) {
      return false;
    }
    return types.includes(shipment.type);
  }

  render() {
    return (
      <div>

        <div className="row row-small">
          <div className="col-md-6 col-lg-6">
            <table className="table table-details" >
              <tbody>
                <tr className="row-no-top-border-and-padding">
                  <td width={200} className="header-text">Status</td>
                  <td><LoadingContent mustBeLoaded={this.props.shipment} content={() => <span className={"badge " + Mapper.toShipmentStatusBadge(this.props.shipment.status)} >{Mapper.fromShipmentStatus(this.props.shipment.status)}</span> } /></td>
                </tr>
                <tr>
                  <td width={200} className="header-text">Created at</td>
                  <td><LoadingContent mustBeLoaded={this.props.shipment} content={() => Converter.toDate(this.props.shipment.created_at)} /></td>
                </tr>
                { (this.isShipmentOfType(this.props.shipment, [ "forwarder", "admin" ])) &&
                  <tr>
                    <td width={200} className="header-text">Customer</td>
                    <td><LoadingContent mustBeLoaded={this.props.shipment} content={() => this.props.shipment.customer.name} /></td>
                  </tr>
                }
                { (this.isShipmentOfType(this.props.shipment, [ "forwarder", "admin" ])) &&
                  <tr>
                    <td width={200} className="header-text">Customer contact</td>
                    <td><LoadingContent mustBeLoaded={this.props.shipment} content={() => (this.props.shipment.customer_contact ? this.props.shipment.customer_contact.name : "-")} /></td>
                  </tr>
                }
                { (this.isShipmentOfType(this.props.shipment, [ "forwarder", "admin" ])) &&
                  <tr>
                    <td width={200} className="header-text">Agent</td>
                    <td><LoadingContent mustBeLoaded={this.props.shipment} content={() => (this.props.shipment.agent ? this.props.shipment.agent.name : "-")} /></td>
                  </tr>
                }
                { (this.isShipmentOfType(this.props.shipment, [ "customer", "forwarder", "admin" ])) &&
                  <tr>
                    <td width={200} className="header-text">Quote</td>
                    <td><LoadingContent mustBeLoaded={this.props.shipment} content={() => ((this.props.shipment.quote && this.props.shipment.quote.id) ? <Link to={`/quotes/all/${this.props.shipment.quote.id}`}>Quote {this.props.shipment.quote.id}</Link> : "-")} /></td>
                  </tr>
                }
                <tr>
                  <td width={200} className="header-text">Reference</td>
                  <td><LoadingContent mustBeLoaded={this.props.shipment} content={() => this.props.shipment.reference} /></td>
                </tr>
                <tr>
                  <td width={200} className="header-text">Incoterms</td>
                  <td><LoadingContent mustBeLoaded={this.props.shipment} content={() => Mapper.fromIncoterms(this.props.shipment.incoterms)} /></td>
                </tr>
                <tr>
                  <td width={200} className="header-text">Consignee type</td>
                  <td><LoadingContent mustBeLoaded={this.props.shipment} content={() => Mapper.fromConsigneeType(this.props.shipment.consignee_type)} /></td>
                </tr>
                {this.props.shipment && this.props.shipment.consignee &&
                  <tr>
                    <td width={200} className="header-text">Consignee</td>
                    <td>{this.props.shipment.consignee.name}</td>
                  </tr>
                }
                {this.props.shipment && !this.props.shipment.consignee &&
                  <tr>
                    <td width={200} className="header-text">Consignee name</td>
                    <td>{this.props.shipment.consignee_name}</td>
                  </tr>
                }
                {this.props.shipment && !this.props.shipment.consignee &&
                  <tr>
                    <td width={200} className="header-text">Consignee phone</td>
                    <td>{this.props.shipment.consignee_phone}</td>
                  </tr>
                }
                {this.props.shipment && !this.props.shipment.consignee &&
                  <tr>
                    <td width={200} className="header-text">Consignee email</td>
                    <td>{this.props.shipment.consignee_email}</td>
                  </tr>
                }
                { (this.isShipmentOfType(this.props.shipment, [ "forwarder", "admin" ])) &&
                  <tr>
                    <td width={200} className="header-text">Internal notes</td>
                    <td><LoadingContent mustBeLoaded={this.props.shipment} content={() => <span style={{ whiteSpace: "pre", textWrap: "wrap" }}>{this.props.shipment.internal_notes}</span>} /></td>
                  </tr>
                }
              </tbody>
            </table>
          </div>
          <div className="col-md-6 col-lg-6">
            <table className="table table-details" >
              <tbody>
                { (this.isShipmentOfType(this.props.shipment, [ "forwarder", "agent", "admin" ])) &&
                  <tr className="row-no-top-border-and-padding">
                    <td width={200} className="header-text">{ this.isShipmentOfType(this.props.shipment, ["agent"]) ? "Comments" : "Agent comments"}</td>
                    <td>
                      <LoadingContent rows={3} mustBeLoaded={this.props.shipment} content={() => this.getComments(this.props.shipment.agent_comments)} />
                      {this.props.shipment && !this.state.is_agent_commenting &&
                        <AsyncButton
                          variant="outlined"
                          color="secondary"
                          onClick={this.addAgentComment.bind(this)}
                          icon="message"
                          text="Add Comment" />
                      }
                      {this.state.is_agent_commenting &&
                        <form autoComplete="off" onSubmit={this.agentCommentShipment.bind(this)}>                            
                          <div className="row">
                            <div className="col-md-12">
                              <MultilineTextField
                                label="Comment"
                                variant="outlined"
                                fullWidth
                                required
                                min_rows={4}
                                margin="dense"
                                value={this.state.agent_comment_shipment.comment}
                                name="comment"
                                onChange={this.handleAgentCommentShipmentChange.bind(this)}
                                InputLabelProps={{
                                  shrink: this.state.agent_comment_shipment.comment !== undefined,
                                }}
                              />
                            </div>
                          </div>                            
                          <div className="row">
                            <div className="col-md-12">
                              <AsyncButton
                                type="submit"
                                variant="outlined"
                                color="primary"
                                icon="check"
                                text="Submit" />
                              <AsyncButton
                                className="button-danger-outlined"
                                variant="outlined"
                                color="secondary"
                                onClick={this.cancelAddAgentComment.bind(this)}
                                icon="close"
                                text="Cancel" />
                            </div>
                          </div>
                        </form>
                      }
                    </td>
                  </tr>
                }
                { (this.isShipmentOfType(this.props.shipment, [ "forwarder", "customer", "admin" ])) &&
                  <tr className={ this.isShipmentOfType(this.props.shipment, ["customer"]) ? "row-no-top-border-and-padding" : ""}>
                  <td width={200} className="header-text">{ this.isShipmentOfType(this.props.shipment, ["customer"]) ? "Comments" : "Customer comments"}</td>
                    <td>
                      <LoadingContent rows={3} mustBeLoaded={this.props.shipment} content={() => this.getComments(this.props.shipment.customer_comments)} />
                      <div className="spacing-top">
                        {this.props.shipment && !this.state.is_customer_commenting &&
                          <AsyncButton
                            variant="outlined"
                            color="secondary"
                            onClick={this.addCustomerComment.bind(this)}
                            icon="message"
                            text="Add Comment" />
                        }
                        {this.state.is_customer_commenting &&
                          <form autoComplete="off" onSubmit={this.customerCommentShipment.bind(this)}>                            
                            <div className="row">
                              <div className="col-md-12">
                                <MultilineTextField
                                  label="Comment"
                                  variant="outlined"
                                  fullWidth
                                  required
                                  min_rows={4}
                                  margin="dense"
                                  value={this.state.customer_comment_shipment.comment}
                                  name="comment"
                                  onChange={this.handleCustomerCommentShipmentChange.bind(this)}
                                  InputLabelProps={{
                                    shrink: this.state.customer_comment_shipment.comment !== undefined,
                                  }}
                                />
                              </div>
                            </div>                            
                            <div className="row">
                              <div className="col-md-12">
                                <AsyncButton
                                  type="submit"
                                  variant="outlined"
                                  color="primary"
                                  icon="check"
                                  text="Submit" />
                                <AsyncButton
                                  className="button-danger-outlined"
                                  variant="outlined"
                                  color="secondary"
                                  onClick={this.cancelAddCustomerComment.bind(this)}
                                  icon="close"
                                  text="Cancel" />
                              </div>
                            </div>
                          </form>
                        }
                      </div>
                    </td>
                  </tr>
                }
              </tbody>
            </table>
          </div>
        </div>
      </div>
    );
  }
}

export default GeneralTab;

import React from 'react';
import ReactTimeAgo from 'react-time-ago'
import Button from '@material-ui/core/Button';
import Icon from '@material-ui/core/Icon';
import { getShipments } from '../../../services/shipment_service';
import { isReadOnly, hasType } from '../../../services/authentication_service';
import Mapper from '../../../util/mapper';
import Converter from '../../../util/converter';
import RowDataTable from '../../../components/row_data_table';
import TableUtil from '../../../util/table_util';

class Shipments extends React.Component {

  onPaginationChanged(page, size) {
    const searchParams = new URLSearchParams(location.search);
    searchParams.set("page", page);
    searchParams.set("size", size);
    window.history.replaceState(window.history.state, '', `${location.pathname}?${searchParams.toString()}`);
  }

  onSortChanged(sort) {
    const searchParams = new URLSearchParams(location.search);
    searchParams.set("sort", sort);
    window.history.replaceState(window.history.state, '', `${location.pathname}?${searchParams.toString()}`);
  }

  onFilterChanged(filters) {
    const searchParams = new URLSearchParams(location.search);
    searchParams.set("filters", JSON.stringify(filters));
    window.history.replaceState(window.history.state, '', `${location.pathname}?${searchParams.toString()}`);
  }
  
  getShipmentHeaders() {
    var headers = [];
  
    headers.push(TableUtil.getTextColumn("id", "ID", undefined, { pinned: "left" }));
    
    headers.push(TableUtil.getDateColumn(
      "created_at",
      "Created",
      (params) => <ReactTimeAgo date={new Date(params.value)} />,
    ));

    headers.push(TableUtil.getSetColumn(
      "status",
      "Status",
      (params) => <span className={"badge " + Mapper.toShipmentStatusBadge(params.value)} >{Mapper.fromShipmentStatus(params.value)}</span>,
      Object.keys(Mapper.shipmentStatuses()),
      (value) => Mapper.fromShipmentStatus(value)
    ));

    if(hasType("forwarder") || hasType("admin")) {
      headers.push(TableUtil.getTextColumn("customer.name", "Customer"));
    }

    if(hasType("forwarder") || hasType("admin") || hasType("customer")) {
      headers.push(TableUtil.getTextColumn("customer_contact.name", "Customer contact", undefined, { hide: true}));
    }

    if(hasType("forwarder") || hasType("admin")) {
      headers.push(TableUtil.getTextColumn("agent.name", "Agent"));
    }
    
    headers.push(TableUtil.getTextColumn("reference", "Reference"));

    headers.push(TableUtil.getSetColumn(
      "incoterms",
      "Incoterms",
      (params) => Mapper.fromIncotermsShort(params.value),
      Object.keys(Mapper.incoterms()),
      (value) => Mapper.fromIncotermsShort(value)
    ));

    headers.push(TableUtil.getSetColumn(
      "consignee_type",
      "Type",
      (params) => Mapper.fromConsigneeTypeShort(params.value),
      Object.keys(Mapper.consigneeTypes()),
      (value) => Mapper.fromConsigneeTypeShort(value)
    ));

    headers.push(TableUtil.getTextColumn("consignee.name", "Consignee", undefined, { hide: true}));

    headers.push(TableUtil.getSetColumn(
      "transport_type",
      "Transport Type",
      (params) => Mapper.fromTransportTypeShort(params.value),
      Object.keys(Mapper.transportTypes()),
      (value) => Mapper.fromTransportTypeShort(value)
    ));

    headers.push(TableUtil.getTextColumn("pickup_location", "Pickup", undefined, { disable: true}));

    headers.push(TableUtil.getTextColumn("delivery_location", "Delivery", undefined, { disable: true}));

    headers.push(TableUtil.getTextColumn("port_of_loading", "POL"));

    headers.push(TableUtil.getTextColumn("port_of_discharge", "POD"));

    headers.push(TableUtil.getDateColumn(
      "pickup_date",
      "Pickup date",
      (params) => Converter.toDate(params.value),
    ));

    headers.push(TableUtil.getDateColumn(
      "departure_date",
      "Departure date",
      (params) => Converter.toDate(params.value),
    ));


    headers.push(TableUtil.getDateColumn(
      "arrival_date",
      "Arrival date",
      (params) => Converter.toDate(params.value),
    ));

    headers.push(TableUtil.getDateColumn(
      "delivery_date",
      "Delivery date",
      (params) => Converter.toDate(params.value),
    ));

    headers.push(TableUtil.getTextColumn("shipping_line", "Shipping line"));

    headers.push(TableUtil.getNumberColumn(
      "containers",
      "Containers",
      (params) => Converter.formatNumber(params.value, 0),
      { disable: true}
    ));

    headers.push(TableUtil.getNumberColumn(
      "truck_quantity",
      "Trucks",
      (params) => Converter.formatNumber(params.value, 0),
    ));

    headers.push(TableUtil.getTextColumn("booking_number", "Booking number", undefined, { hide: true}));

    headers.push(TableUtil.getTextColumn("master_bl_number", "Master BL number"));

    headers.push(TableUtil.getTextColumn("house_bl_number", "House BL number", undefined, { hide: true}));

    headers.push(TableUtil.getNumberColumn(
      "co2_emissions_kg",
      "Carbon emissions",
      (params) => {
        if(params.value) {
          return Converter.formatNumber(params.value, 2) + " kg";
        } else {
          return "";
        }
      }
    ));
    
    return headers;
  }

  getRows(page, blockSize, sort, filters) {
    return new Promise((resolve, reject) => {
      if(this.props.statuses) {
        filters.push({
          "field": "status",
          "comparator": "in",
          "values": this.props.statuses
        });
      }

      return getShipments(page, blockSize, sort, JSON.stringify(filters))
      .then((response) => {
        if(!response) {
          reject("no data");
          return;
        }
        resolve({ content: response.content, total_elements: response.total_elements });
      }).catch(error => {
        reject(error);
      });
    });
  };

  getPage() {
    const params = new URLSearchParams(this.props.location.search);
    return params.get('page');
  }

  getPageSize() {
    const params = new URLSearchParams(this.props.location.search);
    return params.get('size');
  }

  getSort() {
    const params = new URLSearchParams(this.props.location.search);
    return params.get('sort');
  }

  getFilters() {
    const params = new URLSearchParams(this.props.location.search);
    var filters = params.get('filters');
    if(filters) {
      return JSON.parse(filters);
    }
  }
  
  render() {
    return (
      <div className="container-body">

        <div className="row">
          <div className="col-md">
            <div className="form-header">
              <label>Shipments</label>
            </div>
          </div>
        </div>
        
        { hasType("forwarder") && !isReadOnly() &&
          <div className="row">
            <div className="col-sm">
              <Button
                variant="outlined"
                color="primary"
                onClick={this.props.newShipment}
                startIcon={<Icon>add</Icon>}>
                New
              </Button>
            </div>
          </div>
        }

        <div className="row">
          <div className="col-sm">
            <RowDataTable
              name="shipments_table_state"
              rowModelType="serverSide"
              rowData={{ totalElements: 0, ready: false }}
              getRows={this.getRows.bind(this)}
              columnDefs={this.getShipmentHeaders()}
              overlayNoRowsTemplate={this.props.emptyMessage}
              pagination={true}
              initialPage={this.getPage()}
              initialPageSize={this.getPageSize()}
              initialSort={this.getSort()}
              initialFilters={this.getFilters()}
              defaultPageSize={100}
              onRowClicked={this.props.openShipment.bind(this)}
              onPaginationChanged={this.onPaginationChanged.bind(this)}
              onSortChanged={this.onSortChanged.bind(this)}
              onFilterChanged={this.onFilterChanged.bind(this)}
            />
          </div>
        </div>

      </div>
    );
  }
}

export default Shipments;

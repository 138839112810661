import React from 'react';
import { Link } from 'react-router-dom';
import { Breadcrumb, BreadcrumbItem } from 'reactstrap';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { getWarehouse, getWarehouseStock, exportStockToDocument } from '../../services/warehouse_service';
import confirmStockHistory from '../../components/confirm_stock_history.js';
import ErrorHandler from '../../util/error_handler';
import TableUtil from '../../util/table_util';
import RowDataTable from '../../components/row_data_table';
import Converter from '../../util/converter'
import AsyncButton from '../../components/async_button.js';
import DomUtil from '../../util/dom_util.js';
import fileDownload from 'js-file-download';
import Skeleton from 'react-loading-skeleton';

class WarehouseStock extends React.Component {

  constructor(props) {
    super(props)

    this.state = {
      warehouse_code: this.props.match.params.warehouse_code,
      warehouse: undefined,
      show: 'in_warehouse',
      stock: undefined
    }

    this.rowTableRef = React.createRef();
  }

  componentDidMount() {
    this.getWarehouse();
  }

  getWarehouse() {
    getWarehouse(this.state.warehouse_code).then((warehouse) => {
      if (warehouse) {
        this.setState({ warehouse: warehouse });
      }
    }).catch(error => {
      ErrorHandler.showError(error);
    });
  }

  getRows(page, blockSize, sort, filters) {
    return new Promise((resolve, reject) => {
      filters = filters.concat(this.getShowFilters());

      return getWarehouseStock(this.state.warehouse_code, page, blockSize, sort, JSON.stringify(filters))
      .then((response) => {
        if(!response) {
          reject("no data");
          return;
        }

        resolve({ content: response.content, total_elements: response.total_elements });
      }).catch(error => {
        reject(error);
      });
    });
  };

  getStockHeaders() {
    var headers = [];
  
    headers.push(TableUtil.getTextColumn("part_number", "Part number"));
    headers.push(TableUtil.getTextColumn("order_number", "Order number"));
    headers.push(TableUtil.getTextColumn("reference", "Reference"));
    headers.push(TableUtil.getNumberColumn("quantity", "Quantity"));
    headers.push(TableUtil.getNumberColumn("in_quantity", "In quantity"));
    headers.push(TableUtil.getNumberColumn("out_quantity", "Out quantity"));
    headers.push(TableUtil.getTextColumn("country_of_origin", "Origin"));
    headers.push(TableUtil.getTextColumn("description", "Description"));
    headers.push(TableUtil.getTextColumn("package_number", "Package number"));
    headers.push(TableUtil.getTextColumn("loading_number", "Loading number"));
    headers.push(TableUtil.getTextColumn("arrival_date", "Arrival date", (params) => Converter.toDate(params.value), { disable: true }));
    headers.push(TableUtil.getTextColumn("ready_date", "Ready date", (params) => Converter.toDate(params.value), { disable: true }));
    headers.push(TableUtil.getTextColumn("dispatch_date", "Dispatch date", (params) => Converter.toDate(params.value), { disable: true }));
    
    return headers;
  }

  exportStock(e) {
    e.preventDefault();
    var button = e.currentTarget;
    DomUtil.disableClass(button);

    var sort = this.rowTableRef.current.getSort();
    var filters = this.rowTableRef.current.getFilters();
    filters = filters.concat(this.getShowFilters());

    exportStockToDocument(this.state.warehouse_code, sort, JSON.stringify(filters)).then((blob) => {
      fileDownload(blob, `warehouse_stock_export.xlsx`);
      DomUtil.enableClass(button);
    }).catch((error) => {
      ErrorHandler.showError(error);
      DomUtil.enableClass(button);
    });
  }

  handleShowChange(e) {
    this.setState({ show: e.target.value }, () => {
      this.rowTableRef.current.reloadData();
    });
  }

  isLoaded() {
    return this.state.warehouse;
  }

  getShowFilters() {
    var filters = [];
    if(this.state.show === 'in_warehouse') {
      filters.push({
        "field":"quantity",
        "comparator":"greater_than",
        "value":0
      });
      filters.push({
        "field":"arrival_date",
        "comparator":"not_blank"
      });
    }
    return filters;
  }

  showStockDetails(event) {
    confirmStockHistory(`History of part ${event.data.part_number} with order number ${event.data.order_number} in package ${event.data.package_number}`, event.data.history);
  }

  render() {
    return (
      <div>
        
        <div className="container-content">

          <Breadcrumb>
            <BreadcrumbItem><Link to={`/warehouses/` + this.props.location.search}>Warehouses</Link></BreadcrumbItem>
            <BreadcrumbItem>{this.state.warehouse ? this.state.warehouse.name : ""}</BreadcrumbItem>
            <BreadcrumbItem active>Stock</BreadcrumbItem>
          </Breadcrumb>
          
          <div className="container-body">
            <div className="row">
              <div className="col-md">
                <div className="form-header">
                  <label>{`Warehouse ${this.state.warehouse ? this.state.warehouse.name : ""} - Stock`}</label>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-md">
                <AsyncButton
                  variant="outlined"
                  color="secondary"
                  onClick={this.exportStock.bind(this)}
                  icon="download"
                  text="Export" />                  
                <label className="button-line-header-text">Show</label>
                <FormControl
                  variant="outlined"
                  margin="dense"
                  style={{minWidth: 150}}
                  className='no-margin' >
                  <Select
                    native
                    onChange={this.handleShowChange.bind(this)} >
                      <option key={"stock_in_warehouse"} value="in_warehouse">In warehouse</option>
                      <option key={"stock_all"} value="all">All</option>
                  </Select>
                </FormControl>
              </div>
            </div>
            
            <div className="row">
              <div className="col-sm">
                {this.isLoaded() &&
                  <RowDataTable
                    ref={this.rowTableRef}
                    name="warehouse_stock_table_state"
                    rowModelType="serverSide"
                    rowData={{ totalElements: 0, ready: false }}
                    getRows={this.getRows.bind(this)}
                    columnDefs={this.getStockHeaders()}
                    overlayNoRowsTemplate="There is no stock"
                    pagination={true}
                    defaultPageSize={100}
                    onRowClicked={this.showStockDetails.bind(this)}
                  />
                }
                {!this.isLoaded() &&
                  <Skeleton count={5}/>
                }
              </div>
            </div>
            
          </div>
          
        </div>
      </div >
    );
  }
}

export default WarehouseStock;

import React from 'react';
import { Prompt } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { Breadcrumb, BreadcrumbItem } from 'reactstrap';
import Skeleton from 'react-loading-skeleton';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Icon from '@material-ui/core/Icon';
import AsyncButton from '../../../shared/components/async_button';
import { getShipment, updateShipmentLoad } from '../../../shared/services/shipment_service.js';
import ErrorHandler from '../../../shared/util/error_handler.js';
import PackagesTable from '../../../shared/components/packages_table.js';
import alertDialog from '../../../shared/components/alert.js';
import confirmInbound from '../../../shared/components/confirm_inbound.js'
import DomUtil from '../../../shared/util/dom_util.js';
import Mapper from '../../../shared/util/mapper.js';
import EventBus from '../../../shared/components/event_bus';
import confirmQuestion from '../../../shared/components/confirm_question.js'

class InboundShipment extends React.Component {

  constructor(props) {
    super(props)

    this.state = {
      shipment_id: this.props.match.params.shipment_id,
      shipment: undefined,
      selected_package: undefined,
      changes: false
    };

    this.packagesTableRef = React.createRef();
  }

  componentWillMount() {
    this.getShipment();
  }

  componentWillUnmount() {
    window.removeEventListener('beforeunload', this.beforeunload);
  }

  beforeunload(e) {
    e.preventDefault();
    e.returnValue = true;
  }

  handleChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  onKeyDown(e) {
    if (e.key === 'Enter') {
      if(e.target.name === 'package_number') {
        this.getPackage(e);
      }
      if(e.target.name === 'part_number') {
        this.getParts(e);
      }
    }
  }  

  getPackage(e) {
    e.preventDefault();

    if(!this.state.package_number) {
      return;
    }

    var _package = this.findPackage(this.state.package_number)
    if(!_package) {
      alertDialog("Package not found", `Package number ${this.state.package_number} not found.`);
      return;
    }
    
    this.setState({ package_number: _package.package_number, selected_package: _package})
    this.setPackageNumberFilter(_package.package_number);
  }

  findPackage(number) {
    return this.state.shipment.colli.find(c => this.match(c.package_number, number));
  }

  setPackageNumberFilter(package_number) {
    this.packagesTableRef.current.addFilter("number", package_number);
  }

  clearPackage(e) {
    e.preventDefault();
    this.setState({ selected_package: undefined, package_number: undefined })
    this.setPackageNumberFilter(undefined);
  }

  setUnloadingStatus(part) {
    if(Number(part.unloading_quantity) === Number(part.quantity)) {
      part.unloading_status = 'complete';
    } else {
      part.unloading_status = 'deviation';
    }
  }

  getParts(e) {
    e.preventDefault();

    if(!this.state.part_number) {
      return;
    }

    var parts = this.findParts(this.state.part_number);
    if(!parts || parts.length === 0) {
      alertDialog("Part not found", `Part number ${this.state.part_number} not found in pacakge ${this.state.selected_package.package_number}.`);
      return;
    }

    const clone = JSON.parse(JSON.stringify(parts));
    confirmInbound(`Package ${this.state.selected_package.package_number}`, this.state.selected_package, clone).then((updated_parts) => {
      for(const updated_part of updated_parts) {
        var part = this.state.selected_package.parts.find(p => p.part_number === updated_part.part_number && p.order_number === updated_part.order_number);
        part.unloading_quantity = updated_part.unloading_quantity;
        part.new_package_number = updated_part.new_package_number;
        this.setUnloadingStatus(part);
        if(!part.arrival_date) {
          part.arrival_date = new Date();
        }

        if(part.new_package_number) {
          var new_package = this.findPackage(part.new_package_number);
          if(!new_package) {
            new_package = {
              package_number: part.new_package_number,
              loading_number: this.state.selected_package.loading_number,
              parts: []
            };
            this.state.shipment.colli.push(new_package);
          }

          var existing_part = new_package.parts.find(p => p.part_number === part.part_number && p.order_number === part.order_number);

          if(existing_part) {
            existing_part.quantity += part.quantity;
            existing_part.unloading_quantity += part.unloading_quantity;
            existing_part.loading_quantity += part.loading_quantity;
            this.setUnloadingStatus(existing_part);
          } else {
            new_package.parts.push(part);
          }
  
          var partIndex = this.state.selected_package.parts.indexOf(part);
          this.state.selected_package.parts.splice(partIndex, 1);
  
          if(this.state.selected_package.parts.length === 0) {
            var packageIndex = this.state.shipment.colli.indexOf(this.state.selected_package);
            this.state.shipment.colli.splice(packageIndex, 1);
          }
        }
      }
  
      if(!this.state.changes) {
        window.addEventListener('beforeunload', this.beforeunload);
      }

      this.setState({ shipment: this.state.shipment, selected_package: undefined, changes: true });
      this.setPackageNumberFilter(undefined);
    });
  }

  findParts(number) {
    return this.state.selected_package.parts.filter(c => this.match(c.part_number, number));
  }

  match(n1, n2) {
    var regex = /^(0+|\s)+|\s/g; // Remove spaces and leading zero's
    var match = n1.replace(regex, "") === n2.replace(regex, "");

    if(match) {
      return match;
    }
    
    var startsWithLetter = /^[A-Za-z].*$/.test(n2);
    if(startsWithLetter) {
      let result = n2.slice(1); // remove first letter
      return n1.replace(regex, "") === result.replace(regex, "");
    }

    var endsWithLetter = /^.*[A-Za-z]$/.test(n2);
    if(endsWithLetter) {
      let result = n2.slice(0, -1); // remove last letter
      return n1.replace(regex, "") === result.replace(regex, "");
    }

    return false;
  }

  getShipment() {
    getShipment(this.state.shipment_id).then((shipment) => {
      if (shipment) {
        this.setState({ shipment: shipment }, () => {
          this.setInitialUnloadingStatus();
        });
      }
    }).catch(error => {
      ErrorHandler.showError(error);
    });
  }

  setInitialUnloadingStatus() {
    for(const _package of this.state.shipment.colli) {
      for(const part of _package.parts) {
        if(!part.unloading_status) {
          part.unloading_status = "todo";
        }
      }
    }
    this.setState({ shipment: this.state.shipment });
  }

  isLoaded() {
    return this.state.shipment;
  }

  isActivePath() {    
    return this.props.location.pathname.startsWith('/shipments/active');
  }

  getBasePath() {
    if(this.isActivePath()) {
      return '/shipments/active';
    }
    return '/shipments/all';
  }

  getBreadCrumbTitle() {
    if(this.isActivePath()) {
      return 'Active Shipments';
    }
    return 'All Shipments';
  }

  getCount(status) {
    var count = 0;

    for(const _package of this.state.shipment.colli) {
      for(const part of _package.parts) {
        if(part.unloading_status === status) {
          count++;
        }
      }
    }

    return count;
  }

  saveInbound(e) {
    e.preventDefault();
    var form = e.currentTarget;
    confirmQuestion("Please provide", "Are you finished with the inbound processing of this shipment?").then((response) => {
      DomUtil.disableFormSubmitButtonClass(form);
      var shipment = Mapper.toShipmentRequestLoad(this.state.shipment);
      if(response.answer === true) {
        shipment.status = "delivered";
        for(const _package of shipment.colli) {
          for(const part of _package.parts) {
            if(!part.unloading_quantity) {
              part.unloading_quantity = 0;
            }
          }
        }
      }
      updateShipmentLoad(this.state.shipment_id, shipment).then(() => {
        this.setState({ changes: false }, () => {
          EventBus.dispatch("updateShipment");
          this.props.history.push(`${this.getBasePath()}/${this.state.shipment_id}${this.props.location.search}`);
        });
      }).catch(error => {
        ErrorHandler.showError(error);
        DomUtil.enableFormSubmitButtonClass(form);
      });
    });
  }

  render() {
    return (
      <div>
        
        <Prompt when={this.state.changes} message="Are you sure you want to leave this page, data is not saved." />

        <div className="container-content">

          <Breadcrumb>
            <BreadcrumbItem><Link to={`${this.getBasePath()}/${this.props.location.search}`}>{this.getBreadCrumbTitle()}</Link></BreadcrumbItem>
            <BreadcrumbItem><Link to={`${this.getBasePath()}/${this.state.shipment_id}${this.props.location.search}`}>{this.state.shipment_id}</Link></BreadcrumbItem>
            <BreadcrumbItem active>Inbound</BreadcrumbItem>
          </Breadcrumb>
          
          {this.isLoaded() &&
            <div>
              <div className="container-body">
                <div className="row">
                  <div className="col-md">
                    <div className="form-header">
                      <label>Inbound Shipment {this.state.shipment_id}</label>
                    </div>
                  </div>
                </div>

                <form autoComplete="off" onSubmit={this.saveInbound.bind(this)}>
                  <div className="row row-small">
                    <div className="col-md-1 col-lg-1">
                      <div className='button-center-form'>
                        <AsyncButton
                          type="submit"
                          variant="outlined"
                          color="primary"
                          icon="check"
                          text="Save" />
                      </div>
                    </div>
                  </div>
                </form>
              </div>

              <div className="container-body">
                <div className="row">
                  <div className="col-md">
                    <div className="form-header">
                      <label>Colli</label>
                    </div>
                  </div>
                </div>
                
                <div className="row row-small">
                  <div className="col-md-12 col-lg-6">
                    <table className="table table-details" >
                      <tbody>
                        <tr className="row-no-top-border-and-padding">
                          <td width={200} className="header-text">Todo</td>
                          <td>{this.getCount("todo")}</td>
                        </tr>
                        <tr>
                          <td width={200} className="header-text">Complete</td>
                          <td>{this.getCount("complete")}</td>
                        </tr>
                        <tr>
                          <td width={200} className="header-text">Deviation</td>
                          <td>{this.getCount("deviation")}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>

                {!this.state.selected_package &&
                  <div className="row row-small">
                    <div className="col-md-3">
                      <span className='text-padding'>Scan or enter package number..</span>
                      <div className='file-input-download-parent'>
                        <TextField
                          label="Package number"
                          variant="outlined"
                          fullWidth
                          required
                          autoFocus
                          margin="dense"
                          name="package_number"
                          onKeyDown={this.onKeyDown.bind(this)}
                          onChange={this.handleChange.bind(this)} 
                        />
                        <Button
                          className="button-icon-only button-inline clear-form-button"
                          color="primary"
                          type="button"
                          disabled={!this.state.package_number}
                          onClick={this.getPackage.bind(this)}
                          startIcon={<Icon>search</Icon>} />
                      </div>                      
                    </div>
                  </div>
                }
                {this.state.selected_package &&
                  <div className="row row-small">
                    <div className="col-md-3">
                      <span className='text-padding'>Selected package:</span>
                      <div className='file-input-download-parent'>
                        <TextField
                          label="Package number"
                          variant="outlined"
                          fullWidth
                          required
                          margin="dense"
                          value={this.state.selected_package.package_number}
                          disabled={true}
                        />
                        <Button
                          className="button-icon-only button-inline clear-form-button"
                          color="secondary"
                          type="button"
                          onClick={this.clearPackage.bind(this)}
                          startIcon={<Icon>clear</Icon>} />
                      </div>
                    </div>
                    <div className="col-md-3">
                      <span className='text-padding'>Scan or enter part number..</span>
                      <div className='file-input-download-parent'>
                        <TextField
                          label="Part number"
                          variant="outlined"
                          fullWidth
                          required
                          autoFocus
                          margin="dense"
                          name="part_number"
                          onKeyDown={this.onKeyDown.bind(this)}
                          onChange={this.handleChange.bind(this)} 
                        />
                        <Button
                          className="button-icon-only button-inline clear-form-button"
                          color="primary"
                          type="button"
                          disabled={!this.state.part_number}
                          onClick={this.getParts.bind(this)}
                          startIcon={<Icon>search</Icon>} />
                      </div>   
                    </div>
                  </div>
                }
                
                <div className="row">
                  <div className="col-sm">
                    <PackagesTable
                        ref={this.packagesTableRef}
                        name="shipment_inbound_packages_table_state"
                        data={this.state.shipment}
                        headers={[
                          "number",
                          "loading_number",
                          "order_number",
                          "quantity",
                          "unloading_quantity",
                          "scan_unloading_status",
                          "reference",
                          "country_of_origin",
                          "description",
                          "arrival_date"
                        ]}
                    />
                  </div>
                </div>
              </div>
            </div>
          }
          
          {!this.isLoaded() &&
            <div className="container-body">
              <div className="row">
                <div className="col-md">
                  <div className="form-header">
                    <label>Inbound Shipment {this.state.shipment_id}</label>
                  </div>
                </div>
              </div>          
              <div className="row row-small">
                <div className="col-md-12 col-lg-8">
                  <Skeleton count={5}/>
                </div>
              </div>
            </div>
          }

        </div>
      </div>
    );
  }
}

export default InboundShipment;

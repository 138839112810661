import React from 'react';
import Mapper from '../../../util/mapper.js';
import PackagesTable from "../../../components/packages_table.js";
import LoadingContent from '../../../components/loading_content.js';
import AsyncButton from '../../../components/async_button.js';
import Converter from '../../../util/converter.js'
import { exportColliToDocument } from '../../../services/shipment_service.js';
import DomUtil from '../../../util/dom_util.js';
import ErrorHandler from '../../../util/error_handler.js';
import fileDownload from 'js-file-download';

class ColliTab extends React.Component {

  getTotalPackages() {
    if(!this.props.shipment.colli) {
      return 0;
    }
    return this.props.shipment.colli.length;
  }

  getTotalParts() {
    if(!this.props.shipment.colli) {
      return 0;
    }

    var total = 0;
    this.props.shipment.colli.forEach(_package => {
      if(_package.parts) {
        total += _package.parts.length;
      }
    });
    return total;
  }

  exportColli(e) {
    e.preventDefault();
    var button = e.currentTarget;
    DomUtil.disableClass(button);

    exportColliToDocument(this.props.shipment.id).then((blob) => {
      fileDownload(blob, `shipment_${this.props.shipment.id}_colli_export.xlsx`);
      DomUtil.enableClass(button);
    }).catch((error) => {
      ErrorHandler.showError(error);
      DomUtil.enableClass(button);
    });
  }

  getLoadingStatusBadge(status) {
    if(!status) {
      return "-";
    }
    return <span className={"badge " + Mapper.toLoadingStatusBadge(status)} >{Mapper.fromLoadingStatus(status)}</span>;
  }

  render() {
    return (
      <div>

        <div className="row row-small">
          <div className="col-md-12 col-lg-6">
            <table className="table table-details" >
              <tbody>
                <tr className="row-no-top-border-and-padding">
                  <td width={200} className="header-text">Total packages</td>
                  <td><LoadingContent mustBeLoaded={this.props.shipment} content={() => Converter.formatNumber(this.getTotalPackages(), 0)} /></td>
                </tr>
                <tr>
                  <td width={200} className="header-text">Total parts</td>
                  <td><LoadingContent mustBeLoaded={this.props.shipment} content={() => Converter.formatNumber(this.getTotalParts(), 0)} /></td>
                </tr>
                <tr>
                  <td width={200} className="header-text">Unloading status</td>
                  <td><LoadingContent mustBeLoaded={this.props.shipment} content={() => this.getLoadingStatusBadge(this.props.shipment.unloading_status)} /></td>
                </tr>
                <tr>
                  <td width={200} className="header-text">Loading status</td>
                  <td><LoadingContent mustBeLoaded={this.props.shipment} content={() => this.getLoadingStatusBadge(this.props.shipment.loading_status)} /></td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        <div className="row">
          <div className="col-md">
            <AsyncButton
              variant="outlined"
              color="secondary"
              onClick={this.exportColli.bind(this)}
              icon="download"
              text="Export" />
          </div>
        </div>

        <div className="row">
          <div className="col-sm">
            <PackagesTable
                name="shipment_packages_table_state"
                data={this.props.shipment}
                headers={[ 
                  "number",
                  "loading_number",
                  "order_number",
                  "quantity",
                  "unloading_quantity",
                  "unloading_status",
                  "loading_quantity",
                  "loading_status",
                  "reference",
                  "country_of_origin",
                  "description",
                  "arrival_date",
                  "ready_date",
                  "dispatch_date"
                ]}
            />
          </div>
        </div>
        
      </div>
    );
  }
}

export default ColliTab;

import React from 'react';
import { Breadcrumb, BreadcrumbItem } from 'reactstrap';
import { LineChart, Line, XAxis, YAxis, Label, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import CountUp from 'react-countup';
import { getShipmentStatistics, getShipmentCount } from '../../services/shipment_service'
import { getQuoteCount } from '../../services/quote_service';
import ErrorHandler from '../../util/error_handler';
import Skeleton from 'react-loading-skeleton';
import { hasType } from '../../services/authentication_service';

class Dashboard extends React.Component {

  constructor(props) {
    super(props)

    this.state = {
      shipment_statistics: undefined,
      quote_count: undefined,
      shipment_count: undefined
    }
  }

  formatTickItem(tickItem) {
    return new Date(tickItem).toLocaleString('en-En',{ year: "numeric", month: "short", day: "numeric"});
  }

  componentDidMount() {
    this.getShipmentStatistics();
    this.getQuoteCount();
    this.getShipmentCount();
  }

  getShipmentStatistics() {
    getShipmentStatistics().then((shipment_statistics) => {
      if (shipment_statistics) {
        this.setState({ shipment_statistics: shipment_statistics });
      }
    }).catch(error => {
      ErrorHandler.showError(error);
    });
  }

  getQuoteCount() {
    if(hasType("agent")) {
      return;
    }
    
    getQuoteCount(["pending"]).then((quote_count) => {
      if (quote_count) {
        this.setState({ quote_count: quote_count });
      }
    }).catch(error => {
      ErrorHandler.showError(error);
    });
  }

  getShipmentCount() {
    getShipmentCount().then((shipment_count) => {
      if (shipment_count) {
        this.setState({ shipment_count: shipment_count });
      }
    }).catch(error => {
      ErrorHandler.showError(error);
    });
  }

  getBigNumber(label, value, color, fontSize) {
    return (
      <div className="col-md col-big-number">
          <label className='big-number-label' style={{ color: color }}>{label}</label>
          {value !== undefined ? <span style={{ fontSize: fontSize }} >{<CountUp end={value} />}</span> : <Skeleton height={50}/>}          
      </div>
    );
  }

  getActiveQuotesCount() {
    if(!this.state.quote_count) {
      return;
    }
    return this.state.quote_count.pending + this.state.quote_count.quoted;
  }

  getActiveShipmentsCount() {
    if(!this.state.shipment_count) {
      return;
    }
    return this.state.shipment_count.opened + this.state.shipment_count.ready + this.state.shipment_count.received + this.state.shipment_count.in_transit + this.state.shipment_count.at_destination + this.state.shipment_count.delivered;
  }

  getMockData() {
    var data = [];
    var date = new Date();
    for(var i = 0; i < 90; i++) {      
      data.push({ date: date, opened: 0, ready: 0, received: 0, in_transit: 0, at_destination: 0, delivered: 0})
      date = new Date(date.getTime() - 24 * 60 * 60 * 1000);
    }
    return data.reverse();
  }

  render() {
    return (
      <div>
        
        <div className="container-content">

          <Breadcrumb>
            <BreadcrumbItem active>Dashboard</BreadcrumbItem>
          </Breadcrumb>
          
          { !hasType("agent") &&
            <div className="container-body">
              <div className="row">
                <div className="col-md">
                  <div className="form-header">
                    <label>Active quotes</label>
                  </div>
                </div>
              </div>

              <div className="row">
                {this.getBigNumber("Total", this.getActiveQuotesCount(), "#000000", 40)}
                {this.getBigNumber("Pending", this.state.quote_count ? this.state.quote_count.pending : undefined, "#6c757d", 30)}
                {this.getBigNumber("Quoted", this.state.quote_count ? this.state.quote_count.quoted : undefined, "#006D93", 30)}
              </div>
            </div>
          }

          <div className="container-body">
            <div className="row">
              <div className="col-md">
                <div className="form-header">
                  <label>Active shipments</label>
                </div>
              </div>
            </div>

            <div className="row">
              {this.getBigNumber("Total", this.getActiveShipmentsCount(), "#000000", 40)}
              {this.getBigNumber("Opened", this.state.shipment_count ? this.state.shipment_count.opened : undefined, "#006D93", 30)}
              {this.getBigNumber("Ready", this.state.shipment_count ? this.state.shipment_count.ready : undefined, "#FF7300", 30)}
              {this.getBigNumber("Received", this.state.shipment_count ? this.state.shipment_count.received : undefined, "#F9BE0E", 30)}
              {this.getBigNumber("In transit", this.state.shipment_count ? this.state.shipment_count.in_transit : undefined, "#8978DA", 30)}
              {this.getBigNumber("At destination", this.state.shipment_count ? this.state.shipment_count.at_destination : undefined, "#BD4F6C", 30)}
              {this.getBigNumber("Delivered", this.state.shipment_count ? this.state.shipment_count.delivered : undefined, "#5C9C38", 30)}
            </div>
          </div>

          <div className="container-body">
            <div className="row">
              <div className="col-md">
                <div className="form-header">
                  <label>Shipment history</label>
                </div>
              </div>
            </div>

            {this.state.shipment_statistics &&
              <div className="row">
                <div className="col-sm-12">
                  <ResponsiveContainer width="100%" height="100%" minHeight="300px">
                    <LineChart data={this.state.shipment_statistics.items} >
                      <CartesianGrid strokeDasharray="3 3" />
                      <XAxis dataKey="date" tickFormatter={this.formatTickItem} minTickGap={50} />
                      <YAxis >
                        <Label angle={-90} value='Shipments' position='insideLeft' style={{textAnchor: 'middle'}} />
                      </YAxis>
                      <Tooltip labelFormatter={this.formatTickItem} wrapperStyle={{ fontWeight: 600 }} />
                      <Legend iconType='plainline' wrapperStyle={{fontWeight: 600}} />
                      <Line type="basis" dataKey="opened" name="Opened" stroke="#006d93" strokeWidth={2} dot={false} />
                      <Line type="basis" dataKey="ready" name="Ready" stroke="#FF7300" strokeWidth={2} dot={false} />
                      <Line type="basis" dataKey="received" name="Received" stroke="#F9C80E" strokeWidth={2} dot={false} />
                      <Line type="basis" dataKey="in_transit" name="In transit" stroke="#8978DA" strokeWidth={2} dot={false} />
                      <Line type="basis" dataKey="at_destination" name="At destination" stroke="#BD4F6C" strokeWidth={2} dot={false} />
                      <Line type="basis" dataKey="delivered" name="Delivered" stroke="#5C9C38" strokeWidth={2} dot={false} />
                    </LineChart>
                  </ResponsiveContainer>
                </div>
              </div>
            }

            {!this.state.shipment_statistics &&
              <div className="row">
                <div className="col-sm-12">
                  <ResponsiveContainer width="100%" height="100%" minHeight="300px">
                    <LineChart data={this.getMockData()} >
                      <CartesianGrid strokeDasharray="3 3" />
                      <XAxis dataKey="date" tickFormatter={this.formatTickItem} minTickGap={50} />
                      <YAxis >
                        <Label angle={-90} value='Shipments' position='insideLeft' style={{textAnchor: 'middle'}} />
                      </YAxis>
                      <Legend iconType='plainline' wrapperStyle={{fontWeight: 600}} />
                      <Line type="basis" dataKey="opened" name="Opened" stroke="#006d93" strokeWidth={2} dot={false} visibility={"hidden"} />
                      <Line type="basis" dataKey="ready" name="Ready" stroke="#FF7300" strokeWidth={2} dot={false} visibility={"hidden"} />
                      <Line type="basis" dataKey="received" name="Received" stroke="#F9C80E" strokeWidth={2} dot={false} visibility={"hidden"} />
                      <Line type="basis" dataKey="in_transit" name="In transit" stroke="#8978DA" strokeWidth={2} dot={false} visibility={"hidden"} />
                      <Line type="basis" dataKey="at_destination" name="At destination" stroke="#BD4F6C" strokeWidth={2} dot={false} visibility={"hidden"} />
                      <Line type="basis" dataKey="delivered" name="Delivered" stroke="#5C9C38" strokeWidth={2} dot={false} visibility={"hidden"} />
                    </LineChart>
                  </ResponsiveContainer>
                </div>
              </div>
            }

          </div>
          
        </div>
      </div >
    );
  }
}

export default Dashboard;

import React from 'react';
import { Link } from 'react-router-dom';
import DocumentLink from '@material-ui/core/Link';
import fileDownload from 'js-file-download';
import Tooltip from '@material-ui/core/Tooltip';
import Mapper from '../../../util/mapper';
import Table from "../../../components/table";
import LoadingContent from '../../../components/loading_content.js';
import Converter from '../../../../shared/util/converter'
import { getShipmentDocument } from '../../../services/shipment_service';
import alertDialog from '../../../components/alert.js';

class TransportTab extends React.Component {

  constructor(props) {
    super(props);
    this.mapRef = React.createRef();
  }

  componentDidUpdate() {
    if(this.hasTracking()) {      
      if(!this.mapRef.current.src) {
        this.mapRef.current.src = `https://shipsgo.com/iframe/where-is-my-container/${this.props.shipment.tracking.tracking_number}?tags=hide-search-box&movements=hide`;
      }
    }
  }

  isSeaShipment() {
    if(!this.props.shipment) {
      return false;
    }
    return this.props.shipment.transport_type === "lcl" || this.props.shipment.transport_type === "fcl";
  }

  isFTL() {
    if(!this.props.shipment) {
      return false;
    }
    return this.props.shipment.transport_type === "ftl";
  }

  hasTracking() {
    if(!this.isSeaShipment() || !this.props.shipment || !this.props.shipment.tracking) {
      return false;
    }
    return [ "booked", "loaded", "sailing", "arrived", "discharged" ].includes(this.props.shipment.tracking.status)
  }

  getProofOfDeliveryLink() {
    if(!this.props.shipment.proof_of_delivery) {
      return "-";
    }
    return (
      <span>
        <span className="icon-span"><i className="far fa-file"/></span>
        <DocumentLink
          className="file-download-link"
          component="button"
          variant="body2"
          type="button"
          onClick={this.downloadDocument.bind(this, this.props.shipment.proof_of_delivery)}
          >
            {this.props.shipment.proof_of_delivery.file_name}
        </DocumentLink>
      </span>
    );
  }

  downloadDocument(document) {
    getShipmentDocument(this.props.shipment.id, document.id).then(blob => {
      fileDownload(blob, document.file_name);
    }).catch(error => {
      console.error(error)
      alertDialog("Alert", "Failed to download document.")
    });
  }

  getTransportRouteHeaders() {
    if(this.isSeaShipment()) {
      return [
        { name: "Route", key: "name" },
        { name: "Location", key: "location"},
        { name: "Vessel", key: "vessel"},
        { name: "Estimated date", key: "estimated_date" },
        { name: "Actual date", key: "actual_date" }
      ];      
    }

    return [
      { name: "Route", key: "name" },
      { name: "Location", key: "location"},
      { name: "Estimated date", key: "estimated_date" },
      { name: "Actual date", key: "actual_date" }
    ];
  }

  getVessel(name, imo) {
    var vessel = "";

    if(name) {
      vessel += name;
    } else {
      vessel += "-"
    }

    if(imo) {
      vessel += " (" + imo + ")"
    }

    return vessel;
  }

  getTransportRoute() {
    var route = [];
    var shipment = this.props.shipment;
    
    if(!shipment) {
      return;
    }

    route.push({
      name: "Pickup " + (shipment.pickup_location ? shipment.pickup_location.type : " location"),
      location: this.formatLocation(this.props.shipment.pickup_location),
      vessel: "-",
      estimated_date: Converter.toDate(this.props.shipment.estimated_pickup_date),
      actual_date: Converter.toDate(this.props.shipment.actual_pickup_date)
    });

    if(this.isSeaShipment()) {
      var currentVesselName = this.props.shipment.vessel_name;
      var currentVesselImo = this.props.shipment.vessel_imo;

      route.push({
        name: "Port of loading",
        location: this.formatLocation(this.props.shipment.port_of_loading),
        vessel: this.getVessel(currentVesselName, currentVesselImo),
        estimated_date: Converter.toDate(this.props.shipment.estimated_departure_date),
        actual_date: Converter.toDate(this.props.shipment.actual_departure_date)
      });

      if(shipment.transshipments) {
        shipment.transshipments.forEach((transshipment, index) => {
          route.push({
            name: "Arrival transshipment " + (index + 1),
            location: this.formatLocation(transshipment.port),
            vessel: this.getVessel(currentVesselName, currentVesselImo),
            estimated_date: Converter.toDate(transshipment.estimated_arrival_date),
            actual_date: Converter.toDate(transshipment.actual_arrival_date)
          });

          currentVesselName = transshipment.vessel_name;
          currentVesselImo = transshipment.vessel_imo;

          route.push({
            name: "Departure transshipment " + (index + 1),
            location: this.formatLocation(transshipment.port),
            vessel: this.getVessel(currentVesselName, currentVesselImo),
            estimated_date: Converter.toDate(transshipment.estimated_departure_date),
            actual_date: Converter.toDate(transshipment.actual_departure_date)
          });
        });
      }

      route.push({
        name: "Port of discharge",
        location: this.formatLocation(this.props.shipment.port_of_discharge),
        vessel: this.getVessel(currentVesselName, currentVesselImo),
        estimated_date: Converter.toDate(this.props.shipment.estimated_arrival_date),
        actual_date: Converter.toDate(this.props.shipment.actual_arrival_date)
      });
    }

    route.push({
      name: "Delivery " + (shipment.delivery_location ? shipment.delivery_location.type : " location"),
      location: this.formatLocation(this.props.shipment.delivery_location),
      vessel: "-",
      estimated_date: Converter.toDate(this.props.shipment.estimated_delivery_date),
      actual_date: Converter.toDate(this.props.shipment.actual_delivery_date)
    });

    return route;
  }

  formatLocation(location) {
    if(!location) {
      return "-";
    }
    if(location.type === "address") {
      return location.address + ", " + location.postal_code + " " + location.city + ", " + location.country.name;
    }
    if(location.type === "warehouse") {
      return location.name + " (" + location.address + ", " + location.postal_code + " " + location.city + ", " + location.country.name + ")";
    }
    if(location.type === "port") {
      return location.name + ", " + location.country.name;
    }
    return "-";
  }

  getUrl(url) {
    if(!url) {
      return;
    }
    
    try {
      var parsedURL = new URL(url.startsWith("http") ? url : `https://${url}`);
      return parsedURL;
    } catch {
      return;
    }
  }

  getEmissionsTooltip() {
    return (
      <Tooltip title={<span className='emissions-tooltip'>Powered by Pledge</span>} placement="top" arrow leaveDelay={200} >
        <i className="fas fa-leaf emissions-icon"></i>
      </Tooltip>
    );
  }

  getEmissionsValue() {
    if(!this.props.shipment.co2_emissions_kg) {
      return;
    }
    if(!this.props.shipment.co2_emissions_link) {
      return Converter.formatNumber(this.props.shipment.co2_emissions_kg, 2) + " kg";
    }
    return <a href={this.props.shipment.co2_emissions_link} target="_blank" rel="noreferrer" >{Converter.formatNumber(this.props.shipment.co2_emissions_kg, 2) + " kg"}</a>
  }
  
  render() {
    return (
      <div>

        { !this.hasTracking() &&
          <div className="row row-small">
            <div className="col-md-6">
              <table className="table table-details" >
                <tbody>
                  <tr className="row-no-top-border-and-padding">
                    <td width={200} className="header-text">Transport type</td>
                    <td><LoadingContent mustBeLoaded={this.props.shipment} content={() => Mapper.fromTransportType(this.props.shipment.transport_type)} /></td>
                  </tr>
                  <tr>
                    <td width={200} className="header-text">Carbon emissions {this.getEmissionsTooltip()}</td>
                    <td><LoadingContent mustBeLoaded={this.props.shipment} content={() => this.getEmissionsValue()} /></td>
                  </tr>
                  {this.isSeaShipment() &&
                    <tr>
                      <td width={200} className="header-text">Shipping line</td>
                      <td><LoadingContent mustBeLoaded={this.props.shipment} content={() => this.props.shipment.shipping_line ? this.props.shipment.shipping_line.name : ""} /></td>
                    </tr>
                  }
                  {this.isSeaShipment() &&
                    <tr>
                      <td width={200} className="header-text">Master BL number</td>
                      <td><LoadingContent mustBeLoaded={this.props.shipment} content={() => this.props.shipment.master_bl_number} /></td>
                    </tr>
                  }
                  {this.isSeaShipment() &&
                    <tr>
                      <td width={200} className="header-text">Booking number</td>
                      <td><LoadingContent mustBeLoaded={this.props.shipment} content={() => this.props.shipment.booking_number} /></td>
                    </tr>
                  }
                  {this.isSeaShipment() &&
                    <tr>
                      <td width={200} className="header-text">Initial ETA</td>
                      <td><LoadingContent mustBeLoaded={this.props.shipment} content={() => Converter.toDate(this.props.shipment.initial_estimated_arrival_date)} /></td>
                    </tr>
                  }
                  <tr>
                    <td width={200} className="header-text">Truck fuel type</td>
                    <td><LoadingContent mustBeLoaded={this.props.shipment} content={() => Mapper.fromTruckFuelType(this.props.shipment.truck_fuel_type)} /></td>
                  </tr>
                  {this.isFTL() &&
                    <tr>
                      <td width={200} className="header-text">Number of trucks</td>
                      <td>{Converter.formatNumber(this.props.shipment.truck_quantity, 0)}</td>
                    </tr>
                  }
                </tbody>
              </table>
            </div>
            <div className="col-md-6">
              <table className="table table-details" >
                <tbody>
                  <tr className="row-no-top-border-and-padding">
                    <td width={200} className="header-text">Unloading reference</td>
                    <td><LoadingContent mustBeLoaded={this.props.shipment} content={() => this.props.shipment.unloading_reference} /></td>
                  </tr>
                  <tr>
                    <td width={200} className="header-text">Tracking link</td>
                    <td><LoadingContent mustBeLoaded={this.props.shipment} content={() => <Link to={{ pathname: this.getUrl(this.props.shipment.tracking_link)}} target="_blank">{this.props.shipment.tracking_link}</Link>} /></td>
                  </tr>
                  {this.isSeaShipment() &&
                    <tr>
                      <td width={200} className="header-text">Tracking status</td>
                      <td><LoadingContent mustBeLoaded={this.props.shipment} content={() => this.props.shipment.tracking ? Mapper.fromTrackingStatus(this.props.shipment.tracking.status) : "" } /></td>
                    </tr>
                  }
                  <tr>
                    <td width={200} className="header-text">Proof of delivery</td>
                    <td><LoadingContent mustBeLoaded={this.props.shipment} content={() => this.getProofOfDeliveryLink()} /></td>
                  </tr>
                  {this.isSeaShipment() &&
                    <tr>
                      <td width={200} className="header-text">House BL number</td>
                      <td><LoadingContent mustBeLoaded={this.props.shipment} content={() => this.props.shipment.house_bl_number} /></td>
                    </tr>
                  }
                </tbody>
              </table>
            </div>
          </div>
        }

        { this.hasTracking() &&
          <div className="row row-small">
            <div className="col-md-6">
              <table className="table table-details" >
                <tbody>
                  <tr className="row-no-top-border-and-padding">
                    <td width={200} className="header-text">Transport type</td>
                    <td><LoadingContent mustBeLoaded={this.props.shipment} content={() => Mapper.fromTransportType(this.props.shipment.transport_type)} /></td>
                  </tr>
                  <tr>
                    <td width={200} className="header-text">Carbon emissions {this.getEmissionsTooltip()}</td>
                    <td><LoadingContent mustBeLoaded={this.props.shipment} content={() => this.getEmissionsValue()} /></td>
                  </tr>
                  <tr>
                    <td width={200} className="header-text">Shipping line</td>
                    <td><LoadingContent mustBeLoaded={this.props.shipment} content={() => this.props.shipment.shipping_line ? this.props.shipment.shipping_line.name : ""} /></td>
                  </tr>
                  <tr>
                    <td width={200} className="header-text">Master BL number</td>
                    <td><LoadingContent mustBeLoaded={this.props.shipment} content={() => this.props.shipment.master_bl_number} /></td>
                  </tr>
                  <tr>
                    <td width={200} className="header-text">House BL number</td>
                    <td><LoadingContent mustBeLoaded={this.props.shipment} content={() => this.props.shipment.house_bl_number} /></td>
                  </tr>
                  <tr>
                    <td width={200} className="header-text">Booking number</td>
                    <td><LoadingContent mustBeLoaded={this.props.shipment} content={() => this.props.shipment.booking_number} /></td>
                  </tr>
                  <tr>
                    <td width={200} className="header-text">Initial ETA</td>
                    <td><LoadingContent mustBeLoaded={this.props.shipment} content={() => Converter.toDate(this.props.shipment.initial_estimated_arrival_date)} /></td>
                  </tr>
                  <tr>
                    <td width={200} className="header-text">Truck fuel type</td>
                    <td><LoadingContent mustBeLoaded={this.props.shipment} content={() => Mapper.fromTruckFuelType(this.props.shipment.truck_fuel_type)} /></td>
                  </tr>
                  <tr>
                    <td width={200} className="header-text">Tracking status</td>
                    <td><LoadingContent mustBeLoaded={this.props.shipment} content={() => Mapper.fromTrackingStatus(this.props.shipment.tracking.status)} /></td>
                  </tr>
                  <tr>
                    <td width={200} className="header-text">Tracking link</td>
                    <td><LoadingContent mustBeLoaded={this.props.shipment} content={() => <Link to={{ pathname: this.getUrl(this.props.shipment.tracking_link)}} target="_blank">{this.props.shipment.tracking_link}</Link>} /></td>
                  </tr>
                  <tr>
                    <td width={200} className="header-text">Proof of delivery</td>
                    <td><LoadingContent mustBeLoaded={this.props.shipment} content={() => this.getProofOfDeliveryLink()} /></td>
                  </tr>
                  <tr>
                    <td width={200} className="header-text">Unloading reference</td>
                    <td><LoadingContent mustBeLoaded={this.props.shipment} content={() => this.props.shipment.unloading_reference} /></td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className="col-md-6">
              <iframe ref={this.mapRef} className='mini-map'></iframe>
            </div>
          </div>
        }

        <div className="row row-small">
          <div className="col-sm">
            <Table
              loading_rows={2}
              headers={this.getTransportRouteHeaders()}
              rows={this.getTransportRoute()}
              selectable={false}
              empty_rows_text="There is no route"
            />
          </div>
        </div>
      </div>
    );
  }
}

export default TransportTab;
